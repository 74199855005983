import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/Sidebar/Sidebar";
import { Routes, Route, useLocation } from "react-router-dom";
import Book from "./Pages/Book";
import EditorSidebar from "./components/EditorSidebar";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import Forgot from "./Pages/Forgot";
import Reset from "./Pages/Reset";
import EmailVerification from "./Pages/EmailVerification";
import PrivateRoutes from "./components/Privateroutes";
import AdminPanel from "./components/AdminPanel";
import SuperAdminPanel from "./components/SuperAdminPanel";
import EpubViewer from "./components/EpubViewer";
import Contents from "./components/Contents";
import Navbar from "./components/Navbar/Navbar";
import TitlePage from "./components/TitlePage";
import Copyright from "./components/Copyright";
import Orders from "./components/Orders/Orders";
import Checkout from "./components/Orders/Checkout";
import OrderList from "./components/Orders/OrderList";
import Settings from "./components/Settings/Settings";
import UserTable from "./components/UserTable";
import AdminTable from "./components/AdminTable";
import BookEditor from "./components/BookCover/BookEditor";
import Paymentsuccess from "./components/Orders/Paymentsuccess";
import RequestQuoteForm from "./components/RequestQuoteForm";

// Wrapper for routes requiring Navbar and Sidebar
function PrivateRouteWithLayout({ Component, withSidebar, ...rest }) {
  const location = useLocation();
  const isSignInOrSignUp = ["/", "/signup", "/signin"].includes(
    location.pathname
  );

  return (
    <>
      {/* Navbar should always be on top unless on sign-in or sign-up */}

      <div className="layout-wrapper">
        {!isSignInOrSignUp && <Navbar />}
        <div className={`main-content ${withSidebar ? "with-sidebar" : ""}`}>
          <PrivateRoutes Component={Component} {...rest} />
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/quote" element={<RequestQuoteForm />} />
        <Route path="/reset-password/:token" element={<Reset />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />

        <Route
          path="/books"
          element={
            <PrivateRouteWithLayout Component={Book} withSidebar={true} />
          }
        />
        <Route
          path="/editor/:id"
          element={
            <PrivateRouteWithLayout
              Component={EditorSidebar}
              withSidebar={true}
            />
          }
        />
        <Route
          path="/epub/:id"
          element={
            <PrivateRouteWithLayout Component={EpubViewer} withSidebar={true} />
          }
        />
        <Route
          path="/editor/:id/contents"
          element={
            <PrivateRouteWithLayout Component={Contents} withSidebar={true} />
          }
        />
        <Route
          path="/editor/:id/title-page"
          element={
            <PrivateRouteWithLayout Component={TitlePage} withSidebar={true} />
          }
        />
        <Route
          path="/editor/:id/copyright"
          element={
            <PrivateRouteWithLayout Component={Copyright} withSidebar={true} />
          }
        />
        <Route
          path="/orders/:id"
          element={
            <PrivateRouteWithLayout Component={Orders} withSidebar={true} />
          }
        />
        <Route
          path="/bookcovereditor/:id"
          element={
            <PrivateRouteWithLayout Component={BookEditor} withSidebar={true} />
          }
        />
        <Route
          path="/checkout/:id"
          element={
            <PrivateRouteWithLayout Component={Checkout} withSidebar={true} />
          }
        />
        <Route
          path="/paymentsuccessful"
          element={
            <PrivateRouteWithLayout
              Component={Paymentsuccess}
              withSidebar={true}
            />
          }
        />
        <Route
          path="/orderlist"
          element={
            <PrivateRouteWithLayout Component={OrderList} withSidebar={true} />
          }
        />
        <Route
          path="/usertable"
          element={
            <PrivateRouteWithLayout Component={UserTable} withSidebar={true} />
          }
        />
        <Route
          path="/admintable"
          element={
            <PrivateRouteWithLayout Component={AdminTable} withSidebar={true} />
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRouteWithLayout Component={Settings} withSidebar={true} />
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRouteWithLayout
              Component={AdminPanel}
              withSidebar={false}
            />
          }
        />
        <Route
          path="/superadmin"
          element={
            <PrivateRouteWithLayout
              Component={SuperAdminPanel}
              withSidebar={false}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
