import React, { useContext } from "react";
import { CanvasContext } from "./CanvasContext";

export const sizeList = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "72px",
];

export const fontList = [
  "Arial",
  "Arial Black",
  "Arial Unicode MS",
  "Calibri",
  "Cambria",
  "Cambria Math",
  "Candara",
  `Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif`,
  "Comic Sans MS",
  "Consolas",
  "Constantia",
  "Corbel",
  "Courier New",
  "Georgia",
  "Lucida Sans Unicode",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];

export default function ToolbarCanvas({ isEditEnable }) {
  const { state, actions } = useContext(CanvasContext);

  if (!state || !actions) {
    console.error("CanvasContext state or actions are undefined.");
    return null;
  }

  const { activeSelection, canvasData } = state;

  const handleStyleChange = (styleType, value) => {
    const updatedData = canvasData.map((item) => {
      if (activeSelection?.has(item.id)) {
        return {
          ...item,
          content:
            item.type === "TEXT"
              ? `<span style="${styleType}: ${value};">${item.content}</span>`
              : item.content,
        };
      }
      return item;
    });

    actions.setCanvasData(updatedData);
  };

  const addElement = (type) => {
    actions.addElement(type);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isEditEnable && (
        <div id="toolbar" style={{ display: "flex", gap: "10px" }}>
          <select
            className="ql-font"
            onChange={(e) => handleStyleChange("font-family", e.target.value)}
          >
            {fontList.map((font, index) => (
              <option key={index} value={font}>
                {font}
              </option>
            ))}
          </select>
          <select
            className="ql-size"
            onChange={(e) => handleStyleChange("font-size", e.target.value)}
          >
            {sizeList.map((size, index) => (
              <option key={index} value={size}>
                {size}
              </option>
            ))}
          </select>
          <button
            className="ql-bold"
            onClick={() => handleStyleChange("font-weight", "bold")}
          >
            B
          </button>
          <button
            className="ql-italic"
            onClick={() => handleStyleChange("font-style", "italic")}
          >
            I
          </button>
          <button
            className="ql-underline"
            onClick={() => handleStyleChange("text-decoration", "underline")}
          >
            U
          </button>
          <select
            className="ql-color"
            onChange={(e) => handleStyleChange("color", e.target.value)}
          >
            <option value="red">Red</option>
            <option value="green">Green</option>
            <option value="blue">Blue</option>
            <option value="yellow">Yellow</option>
            <option value="orange">Orange</option>
            <option value="purple">Purple</option>
            <option value="pink">Pink</option>
            <option value="brown">Brown</option>
            <option value="black">Black</option>
            <option value="white">White</option>
            <option value="gray">Gray</option>
            <option value="cyan">Cyan</option>
            <option value="magenta">Magenta</option>
            <option value="#d0d1d2">Light Gray</option>
            <option value="#ff6347">Tomato</option>
            <option value="#ff4500">OrangeRed</option>
            <option value="#800080">Purple</option>
          </select>
        </div>
      )}
      <div
        className="toolbar-item"
        onClick={() => addElement("TEXT")}
        style={{ cursor: "pointer", marginLeft: "10px" }}
      >
        Text
      </div>
      <div
        className="toolbar-item"
        onClick={() => addElement("IMAGE")}
        style={{ cursor: "pointer", marginLeft: "10px" }}
      >
        Image
      </div>
    </div>
  );
}
