import axios from "axios";
import { toast } from "react-toastify";
import { clearState } from "../redux/action/action";
import store from "../redux/store";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  // baseURL: "http://localhost:3001/api", // Replace with your API base URL
  baseURL: "https://app.simplymemoirs.com/api", // Replace with your API base URL
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
      toast.error("Unauthorized access. Please sign in again.");
      localStorage.removeItem("token");
      store.dispatch(clearState());
      // Clear invalid token
      window.location.href = "/signIn"; // Redirect to sign-in page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
