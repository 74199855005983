import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TocIcon from "@mui/icons-material/Toc";
import TitleIcon from "@mui/icons-material/Title";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import TextEditor from "./TextEditor";
import { useSelector, useDispatch } from "react-redux";
import {
  getSingleBookChapter,
  addingNewChapter,
  deleteChapter,
  addSubChapter,
  deleteSubchapter,
} from "../redux/action/action";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useNavigate, useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Contents from "./Contents";
import TitlePage from "./TitlePage";
import Copyright from "./Copyright";
import Preface from "./Preface";
import Foreward from "./Foreward";
import Introduction from "./Introduction";
import Dedication from "./Dedication";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CircularProgress from "@mui/material/CircularProgress";

import Loading from "./Loading";
import axiosInstance from "../utils/axiosInstance";

export default function EditorSidebar() {
  const [selectedItem, setSelectedItem] = useState(0);
  const [isContentsSelected, setIsContentsSelected] = useState(false);

  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSubChapter, setIsSubChapter] = useState(false);
  const [selectedSubChapter, setSelectedSubChapter] = useState(0);
  const [showPopover, setShowPopover] = useState(false);
  const [section, setSection] = useState("");
  const [enabledFlags, setEnabledFlags] = useState({});
  const [documentSections, setDocumentSections] = useState([
    { key: "titlePage", name: "Title Page", icon: <TitleIcon /> },
    { name: "Copyright", icon: <CopyrightIcon />, key: "copyright" },
    { key: "dedication", name: "Dedication", icon: <TocIcon /> },
    { key: "contents", name: "Table of Content", icon: <TocIcon /> },
    { key: "foreword", name: "Foreward", icon: <TocIcon /> },
    { key: "preface", name: "Preface", icon: <TocIcon /> },
    { key: "introduction", name: "Introduction", icon: <TocIcon /> },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const chapters = useSelector(
    (state) => state?.reducer?.singleBook?.response?.chapters
  );

  const params = useParams();
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');

  const loading = useSelector((state) => state.reducer.loading);
  const loadingChapter = useSelector(
    (state) => state.addchapterreducer.loading
  );
  const URL = "https://app.simplymemoirs.com/api"
  const [flags, setFlags] = useState({
    isDedication: true,
    isIntroduction: true,
    isPreface: true,
    isForeword: true,
  });

  useEffect(() => {
    // Fetch book flags from the API
    const fetchFlags = async () => {
      try {
        const response = await axiosInstance.post(
          URL + "/toggleflag", // POST request
          { bookId: params.id }, // Only send bookId
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data.flags, "response.data.flags");
        setEnabledFlags(response.data.flags); // Example response: { isDedication: true, isForeword: false, ... }
      } catch (error) {
        console.error("Error fetching flags:", error);
      }
    };



    fetchFlags();
  }, []);
  useEffect(() => {
    if (params.id) {
      dispatch(getSingleBookChapter(params.id));
    }
  }, [params.id, dispatch]);

  const chapterDeleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteChapter(params.id, id));
      setSelectedItem(0);
      setAnchorEl(null);
    } else console.log("nothing");
  };

  const handleToggle = async (field) => {
    try {
      const response = await axiosInstance.post(
        URL + `/toggleflag`,
        { bookId: params.id, field },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the flags locally after a successful toggle
      setFlags((prevFlags) => ({
        ...prevFlags,
        [field]: !prevFlags[field],
      }));

      console.log(response.data.message);
    } catch (error) {
      console.error("Error toggling flag:", error);
    }
  };

  const resetSelection = () => {
    setSection("");
    setSelectedItem(0);
    setSelectedSubChapter(0);
    setIsSubChapter(false);
  };
  const handleDeleteSection = (key) => {
    setDocumentSections((prevSections) =>
      prevSections.filter((section) => section.key !== key)
    );
  };
  const isSectionDisabled = (key) => {
    const enabledFlagKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}`;
    return (
      ["dedication", "foreword", "preface", "introduction"].includes(key) &&
      !enabledFlags[enabledFlagKey]
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", position: "relative" }}>
        <CssBaseline />

        <div variant="permanent" className="col-2" open={open}>
          {/* <List sx={{ background: "#E8F2FD" }}>
            {documentSections.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                selected={section === item.key}
                onClick={() => {
                  resetSelection();
                  setSection(item.key);
                }}
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    {item.name}
                  </ListItemText>
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSection(item.key);
                    }}
                    sx={{ cursor: "pointer", ml: 2 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
          {/* <List sx={{ background: "#E8F2FD" }}>
            {documentSections.map((item, index) => {
              // Disable the section if the corresponding flag is false
              const isDisabled =
                ["dedication", "foreword", "preface", "introduction"].includes(
                  item.key
                ) && !enabledFlags[`is${item.key.charAt(0).toUpperCase() + item.key.slice(1)}`];

              return (
                <ListItem
                  key={index}
                  disablePadding
                  selected={section === item.key}
                  onClick={() => {
                    if (!isDisabled) {
                      resetSelection();
                      setSection(item.key);
                    }
                  }}
                  sx={{
                    display: "block",
                    opacity: isDisabled ? 0.5 : 1, // Grey out disabled sections
                    pointerEvents: isDisabled ? "none" : "auto", // Disable click for disabled sections
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      {item.name}
                    </ListItemText>
                    <DeleteOutlineIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSection(item.key);
                      }}
                      sx={{ cursor: "pointer", ml: 2 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List> */}

          <List sx={{ background: "#E8F2FD" }}>
            {documentSections.map((item, index) => {
              // Helper function to check if the section is disabled
              const isSectionDisabled = (key) => {
                const enabledFlagKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}`;
                return (
                  ["dedication", "foreword", "preface", "introduction"].includes(key) &&
                  !enabledFlags[enabledFlagKey]
                );
              };

              const isDisabled = isSectionDisabled(item.key);

              return (
                <ListItem
                  key={index}
                  disablePadding
                  selected={section === item.key}
                  onClick={() => {
                    if (!isDisabled) {
                      resetSelection();
                      setSection(item.key);

                    }

                  }}
                  sx={{
                    display: "block",
                    opacity: isDisabled ? 0.5 : 1, // Grey out disabled sections
                    pointerEvents: isDisabled ? "none" : "auto", // Disable click for disabled sections
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>{item.name}</ListItemText>
                    {/* <DeleteOutlineIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSection(item.key);
                      }}
                      sx={{ cursor: "pointer", ml: 2 }}
                    /> */}

                    <DeleteOutlineIcon
                      onClick={async (e) => {
                        e.stopPropagation(); // Prevent triggering the ListItem onClick

                        try {
                          const field = `is${item.key.charAt(0).toUpperCase() + item.key.slice(1)}`; // Generate the flag field dynamically
                          const response = await axiosInstance.post(
                            URL + "/updateflag", // Ensure this matches your backend endpoint
                            { bookId: params.id, field },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          );

                          console.log(response.data.message); // Log success message

                          // Update the local state to disable the section
                          setEnabledFlags((prevFlags) => ({
                            ...prevFlags,
                            [field]: false, // Update the flag to false locally
                          }));

                          // Optionally remove the section from the list
                          setDocumentSections((prevSections) =>
                            prevSections.filter((section) => section.key !== item.key)
                          );
                        } catch (error) {
                          console.error("Error updating flag:", error);
                        }
                      }}
                      sx={{ cursor: "pointer", ml: 2 }}
                    />

                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>



          <List sx={{ background: "#E8F2FD" }}>
            {chapters?.map((text, index) => (
              <ListItem
                selected={!isContentsSelected}
                key={text._id}
                disablePadding
                sx={{ display: "block" }}
                onClick={() => {
                  setIsContentsSelected(false);
                  setSection((item) => !item.key);
                }}
              >
                <Tooltip title={text?.chaptername}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <div className="d-flex align-items-center w-100 justify-content-between flex-column">
                      <div
                        className="d-flex align-items-center flex-row w-100"
                        onClick={() => {
                          setSelectedItem(index);
                          setIsSubChapter(false);
                        }}
                      >
                        <KeyboardArrowDownIcon
                          variant="contained"
                          onClick={(e) => {
                            setSelectedItem(index);
                            handleClick(e);
                            setShowPopover(true);
                          }}
                        />
                        <ListItemText
                          primary={
                            text?.chaptername?.length > 20
                              ? `${text.chaptername.slice(0, 20)}...`
                              : `${text.chaptername}`
                          }
                          sx={{
                            opacity: open ? 1 : 0,
                            marginLeft: "24px",
                            fontFamily: "Poppins !important",
                          }}
                        />
                      </div>
                    </div>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <div>
            <Popover
              id={1}
              open={showPopover}
              anchorEl={anchorEl}
              onClose={() => setShowPopover(false)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ListItemButton
                sx={{ p: 2, cursor: "pointer" }}
                onClick={() => {
                  chapterDeleteHandler(chapters[selectedItem]?._id);
                  setShowPopover(false);
                }}
              >
                Delete
              </ListItemButton>
            </Popover>
          </div>
          <List>
            <ListItem
              disablePadding
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => {
                  dispatch(
                    addingNewChapter(
                      params.id,
                      `Chapter ${chapters?.length + 1}`,
                      ""
                    )
                  );
                }}
                disabled={loadingChapter}
              >
                <AddCircleOutlineIcon
                  sx={{
                    fontSize: "50px",
                    color: "#1C212D",
                  }}
                >
                  {loadingChapter && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </AddCircleOutlineIcon>
              </Button>
            </ListItem>
          </List>
        </div>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {loading && !chapters ? (
            <Loading />
          ) : section === "titlePage" ? (
            <TitlePage />
          ) : section === "copyright" ? (
            <Copyright />
          ) : section === "dedication" ? (
            <Dedication />
          ) : section === "contents" ? (
            <Contents title={chapters} />
          ) : section === "foreword" ? (
            <Foreward />
          ) : section === "preface" ? (
            <Preface />
          ) : section === "introduction" ? (
            <Introduction />
          ) : chapters ? (
            <TextEditor
              key={selectedItem}
              index={selectedItem}
              text={chapters[selectedItem]?.chapterbody}
              title={chapters[selectedItem]?.chaptername}
              chapterId={chapters[selectedItem]?._id}
              isSubChapter={isSubChapter}
              subText={
                chapters[selectedItem]?.subchapters[selectedSubChapter]
                  ?.subchapterbody
              }
              subTitle={
                chapters[selectedItem]?.subchapters[selectedSubChapter]
                  ?.subchaptertitle
              }
              subChapterId={
                chapters[selectedItem]?.subchapters[selectedSubChapter]?._id
              }
              subIndex={selectedSubChapter}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
