import "../components/custom-quill.css";
import AWS from "aws-sdk";
import { paraphrase } from "../api/api";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { preview } from "../redux/action/action";
// import CustomImageResize from "../components/CustomImageResize";
import ImageResize from "quill-image-resize-module-react";
import CustomImageToolbar from "../components/CustomImgFilters";
import heic2any from "heic2any";

// import PageBreakModule from "../components/PageBreak";
// import SceneBreakModule from "../components/SceneBreak";
// import FilterModule from "../components/Filter";
import {
  CardEditableModule,
  ImageBlot,
} from "../components/quill-figure-with-caption";
import { CustomGridModule } from "./../components/img-grid";
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageResize", ImageResize);

// import "quill-image-uploader/dist/quill.imageUploader.min.css";
// Quill.register("modules/pageBreak", PageBreakModule);
// Quill.register("modules/sceneBreak", SceneBreakModule);
Quill.register("modules/imageUploader", ImageUploader);
// Quill.register("modules/customImageResize", CustomImageResize);
Quill.register("modules/customImageToolbar", CustomImageToolbar);
// Quill.register("modules/filter", FilterModule);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style", 'figcaption'];


const filters = [
  { filter: "grayscale(100%)", preview: "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/batman2.jpeg-19" },
  { filter: "sepia(100%)", preview: "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/batman2.jpeg-19" },
  { filter: "none", preview: "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/batman2.jpeg-19" },
];


class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    if (domNode.tagName.toLowerCase() === "img") {
      return {
        src: domNode.getAttribute("src"),
        width: domNode.getAttribute("width"),
        style: domNode.getAttribute("style"), // Includes filter and other inline styles
        float: domNode.style.float || null,
      };
    }

    const imageNode = domNode.querySelector("img");
    const figcaption = domNode.querySelector("figcaption");

    if (imageNode) {
      const style = imageNode.style;

      // Handle figcaption styles
      if (figcaption) {
        figcaption.style.margin = style.margin || "0";
        figcaption.style.width = `${imageNode.width}px`;
        figcaption.style.float = style.float || "none";
        figcaption.style.display = "inline-block";
        figcaption.style.textAlign =
          style.float === "left" ? "left" : style.float === "right" ? "right" : "center";
      }

      // Collect all attributes and styles
      return ImageFormatAttributesList.reduce(
        (formats, attribute) => {
          if (imageNode.hasAttribute(attribute)) {
            formats[attribute] = imageNode.getAttribute(attribute);
          }
          return formats;
        },
        {
          src: imageNode.getAttribute("src"),
          caption: figcaption ? figcaption.innerHTML : null,
          style: imageNode.getAttribute("style"), // Capture all inline styles (including filter)
        }
      );
    }

    return {};
  }

  format(name, value) {
    const domNode =
      this.domNode.tagName.toLowerCase() === "img" ? this.domNode : this.domNode.querySelector("img");
    const figcaption = this.domNode.querySelector("figcaption");

    if (!domNode) return;

    if (name === "src") {
      domNode.setAttribute("src", value);
    } else if (name === "float") {
      domNode.style.float = value || null;
    } else if (name === "style") {
      domNode.setAttribute("style", value); // Apply inline styles (including filter)
    } else if (name === "width") {
      domNode.setAttribute("width", value);
    } else if (name === "caption" && figcaption) {
      figcaption.innerHTML = value || ""; // Update the caption content
    } else {
      super.format(name, value);
    }
  }

  static value(domNode) {
    if (domNode.tagName.toLowerCase() === "img") {
      return {
        src: domNode.getAttribute("src"),
        width: domNode.getAttribute("width"),
        style: domNode.getAttribute("style"), // Extract all inline styles (including filter)
        float: domNode.style.float || null,
      };
    }

    const imageNode = domNode.querySelector("img");
    const figcaption = domNode.querySelector("figcaption");

    if (imageNode) {
      return {
        src: imageNode.getAttribute("src"),
        width: imageNode.getAttribute("width"),
        style: imageNode.getAttribute("style"), // Extract all inline styles
        float: imageNode.style.float || null,
        caption: figcaption ? figcaption.innerHTML : null, // Extract caption
      };
    }

    return {};
  }
}

Quill.register(ImageFormat, true);

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: "us-west-1",
});

const s3 = new AWS.S3();

export let transferData;

export const extractWordsAfterSlash = (text) => {
  const regex = /\/([^<>\r\n]+)/;

  const match = text.match(regex);
  if (match && match[1]) {
    return match[1].trim();
  }
  return "";
};

export const stripHtmlTags = (html) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerHTML || "";
};

export const paraphrasing = async (usertext) => {
  const text = stripHtmlTags(usertext);
  if (text !== "") {
    const { data } = await paraphrase(text);
    return data[0].text;
  } else {
    alert("Please Enter some text");
    return "";
  }
};

export const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { color: [] },
      {
        background: [],
      },
    ],
    [{ size: ["normal", "small", "large", "huge"] }, "size"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ font: [] }],
    [{ align: [] }],
    ["image"],
    ["sceneBreak"],
    ["separator"],
    ["filter"],
    ["borders"],
    ["border-size"],
    ["shadow"],
  ],
  customImageToolbar: {
    filters,
  },

  imageUploader: {
    upload: (file) => {
      return new Promise(async (resolve, reject) => {
        const allowedExtensions = [
          "jpg",
          "jpeg",
          "png",
          "gif",
          "bmp",
          "webp",
          "svg",
          "heic",
        ];
        const extension = file.name.split(".").pop().toLowerCase();
        if (extension === "heic") {
          try {
            // Dynamically import `heic2any` to avoid bundling issues
            const heic2any = (await import("heic2any")).default;

            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.8, // Adjust quality as needed
            });

            // Replace HEIC file with the converted JPEG file
            file = new File(
              [convertedBlob],
              file.name.replace(".heic", ".jpg"),
              { type: "image/jpeg" }
            );
          } catch (error) {
            console.error("HEIC conversion failed:", error);
            alert("Failed to process HEIC file. Please upload a valid image.");
            reject(error);
            return;
          }
        }

        // Check if the converted or original file has a valid extension
        const updatedExtension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(updatedExtension)) {
          reject("Unsupported file format");
          alert("Unsupported file format. Please upload a valid image.");
          return;
        }

        const randomString = Math.floor(Math.random() * 101);
        const fileName = `${file.name}-${randomString}`;
        const params = {
          Bucket: "simplymemoirs",
          Key: `images/${fileName}`,
          Body: file,
          ACL: "public-read",
          ContentType: file.type || "image/" + extension,
        };

        s3.upload(params, (err, data) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            console.log(`File uploaded successfully. URL: ${data.Location}`);
            resolve(data.Location);
          }
        });
      });
    },
  },

  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
  cardEditable: true,
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "image",
  "font",
  "color",
  "background",
  "script",
  "indent",
  "align",
  "paraphrasebtn",
  "save",
  "preview",
  // "pageBreak",
  "sceneBreak",
  "size", // Add font size format
  "caption",
  "width",
  "style",
  "margin",
  "float",
  "display",
  "imageBlot",
  "filter",
  "shadow",
  "borders",
  "style",
  "border-size",
  "grayscale",
  "figcaption"
];
