import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function TitlePage() {
  const listBooks = useSelector((s) => s.reducer.data.books);
  const { id } = useParams();
  const selectedBook = listBooks?.find((book) => book._id === id);
  return (
    <>
      <div
        className="col-11"
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#1E1E1E",
          padding: "15px",
          margin: "0 auto",
          borderRadius: "5px",
        }}
      >
        <h2 style={{ color: "white" }}>Title Page</h2>
        <button
          style={{
            backgroundColor: "white",
            color: "black",
            borderRadius: "5px",
          }}
        >
          Edit Book Details
        </button>
      </div>
      <div className="col-10" style={{ padding: "20px", margin: "20px auto" }}>
        {selectedBook ? (
          <>
            <div style={{ marginBottom: "50px" }}>
              <h6>Title</h6>
              <h2>{selectedBook.title}</h2>
            </div>
            <div style={{ marginBottom: "50px" }}>
              <h6>Subtitle</h6>
              <h2>{selectedBook.subtitle}</h2>
            </div>
            <div style={{ marginBottom: "50px" }}>
              <h6>Author</h6>
              <h2>{selectedBook.author}</h2>
            </div>
          </>
        ) : (
          <p>Book not found</p>
        )}
      </div>
    </>
  );
}
