import React, { useState, useRef, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import "./custom-quill.css";

import { modules, formats, stripHtmlTags } from "../utils/helper";
import ReactQuill, { Quill } from "react-quill";
import { continueAi, customPromptAI } from "../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobilePreview from "./MobilePreview/MobilePreview";
import {
  preview,
  saveChapter,
  saveSubchapter,
  fetchOrder,
} from "../redux/action/action";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AiResponse from "./AiResponse";
import Tooltip from "@mui/material/Tooltip";
import Dictaphone from "./Dictaphone";
Quill.register("modules/imageResize", ImageResize);

function TextEditor(props) {
  let {
    index,
    text,
    title,
    chapterId,
    isSubChapter,
    subIndex,
    subText,
    subTitle,
    subChapterId,
  } = props;
  const [value, setValue] = useState(text);
  const [chapterTitle, setChapterTitle] = useState(title);
  const [isLoading, setIsLoading] = useState(false);
  const [previewing, setPreviewing] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [action, setAction] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const [showBorderSizeList, setShowBorderSizeList] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const quillRef = useRef(null);
  const disableeditor = useSelector((state) => state.editororder.orderData);

  useEffect(() => {
    dispatch(fetchOrder(id));
  }, [dispatch, id]);
  const buttons = document.querySelectorAll(".ql-formats button");
  buttons.forEach((button) => {
    button.addEventListener("mouseover", function () {
      const buttonClass = button.getAttribute("class");
      const cleanedClass = buttonClass.replace(/ql-/g, " ").trim();
      button.setAttribute("data-title", cleanedClass.toUpperCase());
    });

    button.addEventListener("mouseout", function () {
      button.removeAttribute("data-title");
    });
  });

  const chapters = useSelector(
    (state) => state?.reducer?.singleBook?.response?.chapters
  );

  useEffect(() => {
    if (isSubChapter) {
      setValue(subText);
      setChapterTitle(subTitle);
    } else {
      setValue(text);
      setChapterTitle(title);
    }
  }, [isSubChapter, subText, text, subTitle, title]);

  useEffect(() => {
    if (!isSubChapter) {
      if (chapters?.length === 0) {
        return;
      }
      chapters[index].chapterbody = value;
    } else {
      if (chapters[index].subchapters.length === 0) {
        return;
      }
      chapters[index].subchapters[subIndex].subchapterbody = value;
    }
  }, [value, chapters, isSubChapter, index, subIndex]);

  useEffect(() => {
    if (!isSubChapter) {
      if (chapters.length === 0) {
        return;
      }
      chapters[index].chaptername = chapterTitle;
    } else {
      if (chapters[index].subchapters.length === 0) {
        return;
      }
      chapters[index].subchapters[subIndex].subchaptertitle = chapterTitle;
    }
  }, [chapterTitle, isSubChapter, chapters, subIndex]);

  useEffect(() => {
    let timeoutId;

    if (isTyping) {
      timeoutId = setTimeout(() => {
        setIsTyping(false);
      }, 3000);
    } else {
      if (isSubChapter) {
        savingSubChapter();
      } else {
        savingChapter();
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, isTyping, isSubChapter]);

  const handelPreview = async () => {
    setPreviewing(!previewing);
    if (action) {
      setAction(false);
    }
  };

  const handleTitle = (e) => {
    const newTitle = e.target.value;
    setChapterTitle(newTitle);
    setIsTyping(true);
  };

  const handleKeyDown = async (event) => {
    if (event.shiftKey && event.key === "Tab") {
      event.preventDefault();
      const suggest = stripHtmlTags(value);

      if (suggest === "") {
        // suggest= " ";
        toast.error("Error: Please write Prompt ");
      } else if (suggest !== "" && isLoading === false) {
        toast.info("Please wait for AI Repsonse");
        setIsLoading(true);
        try {
          continueAi(suggest)
            .then(({ data }) => {
              const cleanedData = data.replace(/\n/g, "").trim();
              setValue((prevValue) => prevValue + " " + cleanedData);
              if (!isSubChapter) {
                chapters[index].chapterbody = cleanedData;
              } else {
                chapters[index].subchapters[subIndex].subchapterbody =
                  cleanedData;
              }
              setIsLoading(false);
              setIsTyping(true);
            })
            .catch((err) => console.log(err));
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (event.ctrlKey && event.key === "/") {
      event.preventDefault();
      setAction(!action);
      if (previewing) {
        setPreviewing(false);
      }
    }
  };

  const handleChange = (html) => {
    setIsTyping(true);

    setValue(html);
  };
  const handleKeyDown1 = (e) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      quillRef.current.focus();
    }
  };

  const savingChapter = () => {
    dispatch(saveChapter(id, chapterId, chapterTitle, value));
  };

  const savingSubChapter = () => {
    dispatch(saveSubchapter(id, chapterId, subChapterId, chapterTitle, value));
  };

  const continueWithAi = async () => {
    const suggest = stripHtmlTags(value);
    toast.info("Please wait for AI Repsonse");

    if (suggest !== "" && isLoading === false) {
      setIsLoading(true);
      const res = await continueAi(suggest);
      const { data } = res;
      const cleanedData = data.replace(/\n/g, "") + " ".trim();
      setValue((prevValue) => " " + prevValue + " " + cleanedData);
      setIsLoading(false);
    }
  };

  const paraphraselAi = async () => {
    setAction(!action);
    if (previewing) {
      setPreviewing(false);
    }
  };

  // const filters = [
  //   { value: 100, filter: "grayscale(100%)" },
  //   { value: 50, filter: "grayscale(54%)" },
  //   { value: 0, filter: "grayscale(0%)" },
  // ];

  // const list = (
  //   <ul className="filter-list">
  //     {filters.map((filterObj, index) => (
  //       <li key={index}>
  //         <img
  //           onClick={() => {
  //             applyStyleToImage("filter", filterObj.filter);
  //             toggleVisibility("list");
  //           }}
  //           src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/book3.png"
  //           alt="img"
  //           width="100"
  //           style={{
  //             filter: filterObj.filter,
  //             margin: "10px",
  //             zIndex: "400",
  //           }}
  //         />
  //       </li>
  //     ))}
  //   </ul>
  // );

  // const borderStyles = ["20%", "30p%", "40%", "50%"];

  // const borderStyle = (
  //   <ul className="border-list">
  //     {borderStyles.map((size) => (
  //       <li key={size}>
  //         <div
  //           onClick={() => {
  //             applyStyleToImage(
  //               "borderImage",
  //               `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`
  //             );
  //             toggleVisibility("border");
  //           }}
  //           style={{
  //             width: "30px",
  //             height: "30px",
  //             margin: "10px",
  //             zIndex: "440",
  //             border: "4px double black",
  //             borderImage: `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`,
  //           }}
  //         ></div>
  //       </li>
  //     ))}
  //   </ul>
  // );

  // const borderSizes = ["2px", "4px", "6px", "8px", "10px"];

  // const borderSizeList = (
  //   <ul className="border-size-list">
  //     {borderSizes.map((size, index) => (
  //       <li key={size}>
  //         <div
  //           onClick={() => {
  //             applyStyleToImage("borderWidth", size);
  //             toggleVisibility("borderSizeList");
  //           }}
  //           style={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //             borderRadius: "20px",
  //             width: "30px",
  //             height: "30px",
  //             boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
  //             margin: "10px",
  //             zIndex: "50",
  //           }}
  //         >
  //           {size.replace("px", "x")}
  //         </div>
  //       </li>
  //     ))}
  //   </ul>
  // );

  // const shadowList = (
  //   <ul className="shadow-list">
  //     <li>
  //       <div
  //         onClick={() => {
  //           applyStyleToImage("boxShadow", "8px 8px 10px rgba(0, 0, 0, 0.4)");
  //           toggleVisibility();
  //         }}
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           borderRadius: "20px",
  //           width: "30px",
  //           height: "30px",
  //           boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
  //           margin: "10px",
  //           zIndex: "50",
  //         }}
  //       >
  //         8x
  //       </div>
  //     </li>
  //   </ul>
  // );

  // const toggleVisibility = (listType) => {
  //   setShowList(listType === "list" ? !showList : false);
  //   setShowShadow(listType === "shadow" ? !showShadow : false);
  //   setShowBorder(listType === "border" ? !showBorder : false);
  //   setShowBorderSizeList(
  //     listType === "borderSizeList" ? !showBorderSizeList : false
  //   );
  // };

  // useEffect(() => {
  //   const filterBtn = document.querySelector(".ql-filter");
  //   const imgBorders = document.querySelector(".ql-borders");
  //   const borderSizeBtn = document.querySelector(".ql-border-size");
  //   const toggleShadowBtn = document.querySelector(".ql-shadow");

  //   filterBtn?.addEventListener("click", () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth", // Smooth scrolling effect
  //     }); toggleVisibility("list")
  //   });
  //   imgBorders?.addEventListener("click", () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth", // Smooth scrolling effect
  //     }); toggleVisibility("border")
  //   });
  //   borderSizeBtn?.addEventListener("click", () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth", // Smooth scrolling effect
  //     });

  //     toggleVisibility("borderSizeList")
  //   }
  //   );
  //   toggleShadowBtn?.addEventListener("click", () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth", // Smooth scrolling effect
  //     });

  //     toggleVisibility("shadow")
  //   }
  //   );

  //   return () => {
  //     filterBtn?.removeEventListener("click", () => toggleVisibility("list"));
  //     imgBorders?.removeEventListener("click", () =>
  //       toggleVisibility("border")
  //     );
  //     borderSizeBtn?.removeEventListener("click", () =>
  //       toggleVisibility("borderSizeList")
  //     );
  //     toggleShadowBtn?.removeEventListener("click", () =>
  //       toggleVisibility("shadow")
  //     );
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       !event.target.closest(".filter-list") &&
  //       !event.target.closest(".border-list") &&
  //       !event.target.closest(".border-size-list") &&
  //       !event.target.closest(".shadow-list") &&
  //       !event.target.closest(".ql-filter") &&
  //       !event.target.closest(".ql-borders") &&
  //       !event.target.closest(".ql-border-size") &&
  //       !event.target.closest(".ql-shadow")
  //     ) {
  //       setShowList(false);
  //       setShowShadow(false);
  //       setShowBorder(false);
  //       setShowBorderSizeList(false);
  //     }
  //   };

  //   document.body.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.body.removeEventListener("click", handleClickOutside);
  //   };
  // }, [showList, showShadow, showBorder, showBorderSizeList]);
  // function applyStyleToImage(styleKey, styleValue) {
  //   const quill = quillRef?.current?.getEditor();
  //   const range = quill?.getSelection(true);

  //   if (range) {
  //     const [leaf] = quill?.getLeaf(range.index);
  //     const figcaptionNode = leaf?.parent?.prev?.domNode;

  //     if (figcaptionNode) {
  //       const imageNode = figcaptionNode.querySelector("img");

  //       if (imageNode) {
  //         try {
  //           if (styleKey === "borderImage") {
  //             imageNode.style.border = "8px solid transparent";
  //           }
  //           imageNode.style[styleKey] = styleValue;

  //           document.removeEventListener("click", applyStyleToImage);
  //           console.log(`${styleKey} applied successfully:`, styleValue);
  //         } catch (error) {
  //           console.error(`Error applying ${styleKey}:`, error);
  //         }
  //       } else {
  //         console.warn("No img tag found within figcaption.");
  //       }
  //     } else {
  //       console.warn("No figcaption found in the parent hierarchy.");
  //     }
  //   }
  // }




  useEffect(() => {
    const editorContainer = document.querySelector(".ql-editor");
    const handleClick = (event) => {
      const target = event.target;
      console.log(target);
      const tagName = target.tagName.toLowerCase();
      if (tagName === "img" || tagName === "figcaption") {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (editorContainer.contentEditable === "false") {
      editorContainer.addEventListener("click", handleClick);
    }

    return () => {
      editorContainer.removeEventListener("click", handleClick);
    };
  }, []);
  const book = useSelector(
    (state) => state?.reducer?.singleBook?.response?.chapters
  );

  let indexPageContent = "";

  book.forEach((chap, i) => {
    indexPageContent += `<div  style="margin-top: 20px;">
    ${i + 1}.
    ${chap.chaptername}</div>`;
  });

  const actions = [
    {
      title: "AI RESPONSE",
      onClick: continueWithAi,
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="17"
            cy="17"
            r="16"
            stroke="
                    white"
            strokeWidth="2"
          />
          <path
            d="M28.25 15.4425V13.5259H25.75V11.6092C25.748 11.1013 25.484 10.6147 25.0156 10.2556C24.5472 9.89648 23.9124 9.69406 23.25 9.69255H20.75V7.77588H18.25V9.69255H15.75V7.77588H13.25V9.69255H10.75C10.0876 9.69406 9.45283 9.89648 8.98442 10.2556C8.51601 10.6147 8.25198 11.1013 8.25 11.6092V13.5259H5.75V15.4425H8.25V17.3592H5.75V19.2759H8.25V21.1925C8.25198 21.7004 8.51601 22.187 8.98442 22.5462C9.45283 22.9053 10.0876 23.1077 10.75 23.1092H13.25V25.0259H15.75V23.1092H18.25V25.0259H20.75V23.1092H23.25C23.9124 23.1077 24.5472 22.9053 25.0156 22.5462C25.484 22.187 25.748 21.7004 25.75 21.1925V19.2759H28.25V17.3592H25.75V15.4425H28.25ZM23.25 21.1925H10.75V11.6092H23.25V21.1925Z"
            fill="
                    white"
          />
          <path
            d="M16.2016 12.6666H14.5203L12.0078 20.3333H13.2916L13.8716 18.5364H16.7666L17.3316 20.3333H18.6591L16.2016 12.6666ZM14.0403 17.7688L15.3128 13.577H15.3703L16.5991 17.7688H14.0403ZM19.8053 12.6666H21.0553V20.3333H19.8053V12.6666Z"
            fill="
                    white"
          />
        </svg>
      ),
    },
    {
      title: "PARAPHRASE",
      onClick: paraphraselAi,
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17" cy="17" r="16" stroke="white" strokeWidth="2" />
          <path
            d="M9.73057 21.2518C9.0175 21.2518 8.44141 21.6952 8.44141 22.2442C8.44141 22.7931 9.0175 23.2365 9.73057 23.2365H25.2005C25.9136 23.2365 26.4897 22.7931 26.4897 22.2442C26.4897 21.6952 25.9136 21.2518 25.2005 21.2518H9.73057ZM9.73057 17.2822C9.0175 17.2822 8.44141 17.7257 8.44141 18.2746C8.44141 18.8235 9.0175 19.267 9.73057 19.267H25.2005C25.9136 19.267 26.4897 18.8235 26.4897 18.2746C26.4897 17.7257 25.9136 17.2822 25.2005 17.2822H9.73057Z"
            fill="white"
          />
          <path
            d="M9.73057 13.7097C9.0175 13.7097 8.44141 14.1531 8.44141 14.702C8.44141 15.251 9.0175 15.6944 9.73057 15.6944H25.2005C25.9136 15.6944 26.4897 15.251 26.4897 14.702C26.4897 14.1531 25.9136 13.7097 25.2005 13.7097H9.73057ZM9.73057 9.74011C9.0175 9.74011 8.44141 10.1836 8.44141 10.7325C8.44141 11.2814 9.0175 11.7249 9.73057 11.7249H25.2005C25.9136 11.7249 26.4897 11.2814 26.4897 10.7325C26.4897 10.1836 25.9136 9.74011 25.2005 9.74011H9.73057Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "PREVIEW",
      onClick: handelPreview,
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17" cy="17" r="16" stroke="white" strokeWidth="2" />
          <path
            d="M17.8542 24.2C17.8542 23.8906 17.7216 23.6258 17.4564 23.4055C17.1912 23.1852 16.8724 23.075 16.5 23.075C16.1276 23.075 15.8088 23.1852 15.5436 23.4055C15.2784 23.6258 15.1458 23.8906 15.1458 24.2C15.1458 24.5094 15.2784 24.7742 15.5436 24.9945C15.8088 25.2148 16.1276 25.325 16.5 25.325C16.8724 25.325 17.1912 25.2148 17.4564 24.9945C17.7216 24.7742 17.8542 24.5094 17.8542 24.2ZM21.375 21.95V12.05C21.375 11.9281 21.3214 11.8227 21.2142 11.7336C21.107 11.6445 20.98 11.6 20.8333 11.6H12.1667C12.02 11.6 11.893 11.6445 11.7858 11.7336C11.6786 11.8227 11.625 11.9281 11.625 12.05V21.95C11.625 22.0719 11.6786 22.1773 11.7858 22.2664C11.893 22.3555 12.02 22.4 12.1667 22.4H20.8333C20.98 22.4 21.107 22.3555 21.2142 22.2664C21.3214 22.1773 21.375 22.0719 21.375 21.95ZM18.125 10.025C18.125 9.875 18.0347 9.8 17.8542 9.8H15.1458C14.9653 9.8 14.875 9.875 14.875 10.025C14.875 10.175 14.9653 10.25 15.1458 10.25H17.8542C18.0347 10.25 18.125 10.175 18.125 10.025ZM23 9.8V24.2C23 24.6875 22.7856 25.1094 22.3568 25.4656C21.928 25.8219 21.4201 26 20.8333 26H12.1667C11.5799 26 11.072 25.8219 10.6432 25.4656C10.2144 25.1094 10 24.6875 10 24.2V9.8C10 9.3125 10.2144 8.89062 10.6432 8.53438C11.072 8.17812 11.5799 8 12.1667 8H20.8333C21.4201 8 21.928 8.17812 22.3568 8.53438C22.7856 8.89062 23 9.3125 23 9.8Z"
            fill="white"
          />
        </svg>
      ),
    },
    {
      title: "SAVE",
      onClick: () => (isSubChapter ? savingSubChapter() : savingChapter()),
      svg: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17" cy="17" r="16" stroke="white" strokeWidth="2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 13.6667C7 10.9958 7 9.65939 7.92783 8.83017C8.85461 8 10.3482 8 13.3333 8H20.0287C20.8922 8 21.3228 8 21.7113 8.14356C22.0987 8.28711 22.4037 8.561 23.0149 9.10689L24.7629 10.6709C25.3741 11.2168 25.6781 11.4907 25.8396 11.8373C26 12.1848 26 12.5702 26 13.3427V19.3333C26 22.0042 26 23.3406 25.0722 24.1698C24.3956 24.7762 23.4171 24.9396 21.7778 24.9839V22.1081C21.7778 21.4904 21.7778 20.9361 21.7102 20.4865C21.6363 19.9954 21.4653 19.4929 21.0051 19.0802C20.5438 18.6684 19.9812 18.5154 19.4334 18.4493C18.9309 18.3889 18.3113 18.3889 17.621 18.3889H14.3234C13.6331 18.3889 13.0135 18.3889 12.5111 18.4493C11.9622 18.5154 11.4006 18.6684 10.9393 19.0802C10.4791 19.4929 10.3081 19.9963 10.2342 20.4865C10.1667 20.9361 10.1667 21.4904 10.1667 22.1081V24.9282C9.1375 24.8376 8.44506 24.6326 7.92783 24.1698C7 23.3406 7 22.0042 7 19.3333V13.6667ZM19.6667 22.1667V25H13.3333C12.9586 25 12.6071 25 12.2778 24.9981V22.1667C12.2778 21.4725 12.2799 21.0466 12.3263 20.7377C12.3675 20.4676 12.4245 20.4223 12.4319 20.4166C12.4393 20.41 12.4889 20.3581 12.7918 20.3212C13.137 20.2797 13.6131 20.2778 14.3889 20.2778H17.5556C18.3314 20.2778 18.8074 20.2797 19.1526 20.3212C19.4556 20.3581 19.5052 20.4091 19.5115 20.4157H19.5126C19.5199 20.4223 19.5769 20.4676 19.6181 20.7377C19.6646 21.0466 19.6667 21.4725 19.6667 22.1667ZM11.2222 11.7778C10.9423 11.7778 10.6738 11.8773 10.4758 12.0544C10.2779 12.2315 10.1667 12.4717 10.1667 12.7222C10.1667 12.9727 10.2779 13.2129 10.4758 13.39C10.6738 13.5672 10.9423 13.6667 11.2222 13.6667H16.5C16.78 13.6667 17.0484 13.5672 17.2464 13.39C17.4443 13.2129 17.5556 12.9727 17.5556 12.7222C17.5556 12.4717 17.4443 12.2315 17.2464 12.0544C17.0484 11.8773 16.78 11.7778 16.5 11.7778H11.2222Z"
            fill="white"
          />
        </svg>
      ),
    },
  ];

  const ActionButtons = () => (
    <div
      style={{
        display: "flex",
        backgroundColor: "#503981",
        zIndex: 1,
        position: "fixed",
        bottom: 10,
        right: 60,
        width: "150px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      {actions.map((action, index) => (
        <div
          key={index}
          data-title={action.title}
          onClick={action.onClick}
          style={{ margin: "6px" }}
        >
          {action.svg}
        </div>
      ))}
    </div>
  );
  return (
    <div>
      <div className="row">
        <div
          className={`position-relative ${action || previewing ? "col-7" : "col-12"
            }`}
        >
          {/* {showList && list}
          {showBorder && borderStyle}
          {showBorderSizeList && borderSizeList}
          {showShadow && shadowList} */}
          <div
            className="editor-container col-12"
            style={{
              borderRadius: "20px 20px 0px 0px",
              border: "2px solid #503981",
              borderBottom: 0,
            }}
          >
            <div className={action || previewing ? "col-10" : "col-10"}>
              <h3 style={{ marginTop: "10px", display: "flex" }}>
                <Tooltip title={chapterTitle}>
                  <input
                    type="text"
                    className={` ${disableeditor?.readOnly ? "col-3" : "col-10"
                      }`}
                    onChange={handleTitle}
                    onKeyDown={handleKeyDown1}
                    value={chapterTitle}
                    disabled={disableeditor?.readOnly || false}
                    onBlur={() =>
                      isSubChapter ? savingSubChapter() : savingChapter()
                    }
                  />
                </Tooltip>
                {disableeditor?.readOnly && (
                  <div
                    style={{ backgroundColor: "yellow", marginLeft: "20px" }}
                  >
                    <h4>
                      Your order is in processing - no changes can be made to
                      this book
                    </h4>
                  </div>
                )}
              </h3>
            </div>
            <div
              className={
                action || previewing
                  ? "icons-container col-2"
                  : "icons-container col-2 align-items-center"
              }
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="d-flex align-items-center mr-2">
                {isTyping ? (
                  <p className="fade-in-out-text">saving</p>
                ) : (
                  <p className="me-6">saved</p>
                )}
              </div>
              <div>
                <Dictaphone setValue={setValue} />
              </div>
            </div>
          </div>
          <ActionButtons />
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            onKeyDown={handleKeyDown}
            placeholder="Press Shift + Tab for continue writing and Ctrl + / for AI Actions"
            readOnly={disableeditor?.readOnly || false}
            ref={quillRef}
          />
        </div>
        {previewing && (
          <div className="col-5 d-flex flex-column align-items-center">
            <MobilePreview preview={preview} text={value} title={title} />
          </div>
        )}

        {action && <AiResponse value={value} />}
      </div>

      <ToastContainer />
    </div>
  );
}

export default TextEditor;
