import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleBookChapter } from "../redux/action/action";
export default function Contents() {
  const dispatch = useDispatch();
  const params = useParams();
  const chapters = useSelector(
    (state) => state?.reducer?.singleBook?.response?.chapters
  );
  useEffect(() => {
    dispatch(getSingleBookChapter(params.id));
  }, []);
  return (
    <>
      <div
        className='col-11'
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#1E1E1E",
          margin: "0 auto",
          padding: "8px",
          paddingLeft: "12px",
          borderRadius: "5px",
       
        }}
      >
        <h6 style={{ color: "white", margin: "0px", padding: "0px",   textAlign:'left' }}>
          Table of Content
        </h6>
        <h2 style={{ color: "white", margin: "0px", padding: "0px",   textAlign:'left' }}>
          Contents
        </h2>
      </div>
      <div className='col-10' style={{ padding: "20px", margin: "20px auto" ,   textAlign:'left'}}>
        <h2>Table of Content</h2>
        <ul style={{ marginTop: "20px" }}>
          {chapters?.map((chapter, index) => (
            <li
              style={{ fontWeight: "bold", marginBottom: "10px" }}
              key={index}
            >
              {" "}
              {index + 1}.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {chapter.chaptername}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
