import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./orders.css";

const statusColors = {
  "Edit Mode": "#FFC107",
  Preview: "#2196F3",
  "Proofread by Expert": "#4CAF50",
  "Submitted for Print": "#FF5722",
  Printing: "#673AB7",
  Printed: "#9E9E9E",
  Dispatched: "#FF9800",
  Delivered: "#8BC34A",
};

export default function BasicTable() {
  const [rows, setRows] = useState([]);

  const URL = "https://app.simplymemoirs.com/api";

  useEffect(() => {
    const authToken = localStorage.getItem("token");
    axios
      .get(`${URL}/getorders`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
      });
  }, []);

  const handleDelete = (id) => {
    const authToken = localStorage.getItem("token");
    axios
      .delete(`${URL}/deleteorder/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setRows(rows.filter((row) => row._id !== id));
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
      });
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        padding: "30px",
        width: "90vw",
        margin: "30px auto",
        borderRadius: "20px",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#f7f7f7",
      }}
    >
      <Typography
        variant="h4"
        component="h3"
        style={{
          marginBottom: "20px",
          textAlign: "center",
          color: "#1f1f1f", // Primary purple color for title
          fontFamily: "Poppins, sans-serif",
        }}
      >
        My Orders
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <TextField
          variant="outlined"
          placeholder="Search orders..."
          InputProps={{
            style: {
              borderRadius: 15,
              height: "40px",
              paddingLeft: "10px",
              fontFamily: "Poppins, sans-serif",
            },
            endAdornment: (
              <SearchIcon style={{ marginRight: "5px", color: "#6a1ac3" }} />
            ),
          }}
          style={{ width: "100%" }}
        />
      </Box>
      <Table sx={{ minWidth: 650 }}>
        <TableHead
          style={{
            background: "#1f1f1f",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)", // Gradient for table head
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              #
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)", // Gradient for table head
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Order
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
                background: "#1f1f1f",

                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Date Created
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Last Updated
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Status
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Notes
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                color: "#ffffff",
                background: "#1f1f1f",
                fontFamily: "Poppins, sans-serif",
                padding: "16px",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "60px",
                "&:hover": {
                  backgroundColor: "#f1f1f1", // Light hover effect
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                align="center"
                sx={{ padding: 0, fontFamily: "Poppins, sans-serif" }}
              >
                {index + 1}
              </TableCell>
              <TableCell sx={{ fontFamily: "Poppins, sans-serif" }}>
                {row.orderName}
              </TableCell>
              <TableCell sx={{ fontFamily: "Poppins, sans-serif" }}>
                {new Date(row.date).toLocaleDateString()}
              </TableCell>
              <TableCell sx={{ fontFamily: "Poppins, sans-serif" }}>
                {new Date(row.date).toLocaleDateString()}
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    borderRadius: "25px",
                    margin: "0 auto",
                    padding: "5px 10px",
                    backgroundColor: statusColors[row.status] || "#ccc",
                    color: "#fff",
                    textAlign: "center",
                    width: "fit-content",
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {row.status}
                </Box>
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 8,
                      height: "35px",
                      fontFamily: "Poppins, sans-serif",
                    },
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => handleDelete(row._id)}>
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
