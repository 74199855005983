import React from "react";
import "./book.css";

const BookComponent = ({
  title,
  author,
  subtitle,
  imageUrl,
  bookFrontCover,
}) => {
  const truncateText = (text) => {
    return text.length > 12 ? text.substring(0, 12) + "..." : text;
  };

  return (
    <div className="book-container">
      <li>
        <figure className="book-theme">
          {/* Front */}
          <ul className="hardcover_front">
            <li>
              <img
                src={bookFrontCover ? bookFrontCover : imageUrl}
                // alt="Book Cover"
                width="100%"
                style={{ height: "100%" }}
              />
            </li>
            <li></li>
          </ul>
          {/* Pages */}
          <ul className="page">
            <li></li>
            <li>
              <div>
                <p>
                  <b>Title</b>
                </p>
                <p style={{ marginBottom: "12px" }}>{truncateText(title)}</p>
                <p>
                  <b>Subtitle</b>
                </p>
                <p style={{ marginBottom: "12px" }}>{truncateText(subtitle)}</p>
                <p>
                  <b>Author</b>
                </p>
                <p>{truncateText(author)}</p>
              </div>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {/* Back */}
          <ul
            className="hardcover_back"
            style={{ boxShadow: "3px 1px 4px rgba(192, 192, 192)" }}
          >
            <li style={{ boxShadow: "3px 1px 4px rgba(192, 192, 192)" }}></li>
            <li></li>
          </ul>
          <ul className="book_spine">
            <li></li>
            <li></li>
          </ul>
        </figure>
      </li>
      {/* Book Details */}
      <div className="book-details">
        <p title={title}>
          <b>Title:</b> {truncateText(title)}
        </p>
        <p title={subtitle}>
          <b>Subtitle:</b> {truncateText(subtitle)}
        </p>
        <p title={author}>
          <b>Author:</b> {truncateText(author)}
        </p>
      </div>
    </div>
  );
};

export default BookComponent;
