import React, { useContext, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CanvasContext } from "./CanvasContext";
import { fontList, sizeList } from "./ToolbarCanvas";

const Size = Quill.import("attributors/style/size");
Size.whitelist = sizeList;

const Font = Quill.import("attributors/style/font");
Font.whitelist = fontList;

Quill.register(Font, true);
Quill.register(Size, true);

const TextElement = ({ content, id, isReadOnly, dimension }) => {
  const { actions } = useContext(CanvasContext);
  const editorRef = useRef(null);

  const updateEditorValue = (value) => {
    actions?.updateCanvasData({ id, content: value });
  };

  const modules = {
    toolbar: "#toolbar",
  };

  let scale = 1;
  const container = document.querySelector(".quill-container");
  // if (container && dimension) {
  //   const { offsetHeight, offsetWidth } = container;
  //   const { width } = container.getBoundingClientRect();
  //   scale = Math.min(
  //     parseFloat(dimension?.width ?? 0) / offsetWidth
  //   );
  //   console.log(scale);
  // }

  return (
    <div>
      {isReadOnly ? (
        <div
          className="ql-editor"
          style={{
            fontFamily: "Arial",
            fontSize: "13px",
            padding: 0,
          }}
        >
          {ReactHtmlParser(content || "")}
        </div>
      ) : (
        <ReactQuill
          ref={editorRef}
          readOnly={isReadOnly}
          theme="snow"
          className="quill-container"
          modules={modules}
          value={content}
          onChange={updateEditorValue}
        />
      )}
    </div>
  );
};

export default TextElement;
