// // import "../App.css";
// // import * as React from "react";
// // import { useState, useEffect } from "react";
// // import UserTable from "./UserTable";
// // import AdminTable from "./AdminTable";
// // import { styled } from "@mui/material/styles";
// // import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// // import TableRow from "@mui/material/TableRow";
// // import axios from "axios";
// // import OrdersTable from "./Orderstable";
// // import InvoicesTable from "./InvoicesTable";
// // import ShipmentTable from "./ShipmentTable";

// // const StyledTableCell = styled(TableCell)(({ theme }) => ({
// //   [`&.${tableCellClasses.head}`]: {
// //     backgroundColor: theme.palette.common.black,
// //     color: theme.palette.common.white,
// //   },
// //   [`&.${tableCellClasses.body}`]: {
// //     fontSize: 14,
// //   },
// // }));

// // const StyledTableRow = styled(TableRow)(({ theme }) => ({
// //   "&:nth-of-type(odd)": {
// //     backgroundColor: theme.palette.action.hover,
// //   },
// //   // hide last border
// //   "&:last-child td, &:last-child th": {
// //     border: 0,
// //   },
// // }));

// // function SuperAdminPanel() {
// //   const [orders, setOrders] = useState([]);
// //   const [users, setUsers] = useState([]);
// //   const [admins, setAdmins] = useState([]);
// //   const [payments, setPayments] = useState([]);
// //   const [showTable, setShowTable] = useState(true);
// //   const [showAdminContent, setShowAdminContent] = useState(false);
// //   const [showOrdersContent, setShowOrdersContent] = useState(false);
// //   const [showShipmentTable, setShowShipmentTable] = useState(false);
// //   const [showInvoicesTable, setshowInvoicesTable] = useState(false);

// //   // const URL = "http://localhost:3001";
// //   const URL = "https://app.simplymemoirs.com";
// //   const showUsers = () => {
// //     setShowTable(true);
// //     setShowAdminContent(false);
// //     setShowOrdersContent(false);
// //     setshowInvoicesTable(false);
// //   };

// //   const showAdmins = () => {
// //     setShowTable(false);
// //     setShowAdminContent(true);
// //     setShowOrdersContent(false);
// //     setshowInvoicesTable(false);
// //   };
// //   const showOrders = () => {
// //     setShowTable(false);
// //     setShowAdminContent(false);
// //     setShowOrdersContent(true);
// //     setshowInvoicesTable(false);
// //   };
// //   const showShipments = () => {
// //     setShowTable(false);
// //     setShowOrdersContent(false);
// //     setshowInvoicesTable(false);
// //     setShowShipmentTable(true);
// //   };
// //   const showInvoices = () => {
// //     setShowTable(false);
// //     setShowAdminContent(false);
// //     setShowOrdersContent(false);
// //     setshowInvoicesTable(true);
// //   };
// //   const authToken = localStorage.getItem("token");
// //   useEffect(() => {
// //     const fetchUsers = async () => {
// //       try {
// //         const response = await axios.get(`${URL}/api/allusers`, {
// //           headers: {
// //             Authorization: `Bearer ${authToken}`,
// //           },
// //         });
// //         const usersData = response.data.users;
// //         console.log(usersData, "res");
// //         setUsers(usersData);
// //       } catch (error) {
// //         console.error(error);
// //       }
// //     };

// //     fetchUsers();
// //     fetchAdmins();
// //   }, []);

// //   useEffect(() => {
// //     fetchOrders();
// //     fetchPayments();
// //   }, []);
// //   const fetchPayments = async () => {
// //     try {
// //       const response = await axios.get(`${URL}/api/getallpayments`);
// //       setPayments(response.data);
// //     } catch (error) {
// //       console.error("Error fetching payments:", error);
// //     }
// //   };
// //   const fetchOrders = async () => {
// //     try {
// //       const authToken = localStorage.getItem("token");
// //       const response = await axios.get(`${URL}/api/getallorders`, {
// //         headers: {
// //           Authorization: `Bearer ${authToken}`,
// //         },
// //       });
// //       setOrders(response.data);
// //     } catch (error) {
// //       console.error("Error fetching orders:", error);
// //     }
// //   };

// //   const updateOrders = () => {
// //     fetchOrders();
// //   };

// //   const fetchAdmins = async () => {
// //     try {
// //       const response = await axios.get(`${URL}/api/alladmins`, {
// //         headers: {
// //           Authorization: `Bearer ${authToken}`,
// //         },
// //       });
// //       const adminsData = response.data.users;
// //       console.log(adminsData, "res");
// //       setAdmins(adminsData);
// //     } catch (error) {
// //       console.error(error);
// //     }
// //   };
// //   const handleAdmin = async (userId) => {
// //     console.log("hit me");
// //     try {
// //       const response = await axios.patch(
// //         `${URL}/api/makeAdmin/${userId}`,
// //         null,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${authToken}`,
// //           },
// //         }
// //       );

// //       if (response.data.success) {
// //         console.log("User role updated successfully:", response.data.user);
// //         setUsers((prevUsers) =>
// //           prevUsers.filter((user) => user._id !== userId)
// //         );

// //         fetchAdmins();
// //       }
// //     } catch (error) {
// //       console.error("Error updating user role:", error);
// //     }
// //   };
// //   const handleDelete = async (userId) => {
// //     try {
// //       const response = await axios.delete(`${URL}/api/userdelete/${userId}`, {
// //         headers: {
// //           Authorization: `Bearer ${authToken}`,
// //         },
// //       });

// //       if (response.data.success) {
// //         setUsers((prevUsers) =>
// //           prevUsers.filter((user) => user._id !== userId)
// //         );
// //       }
// //     } catch (error) {
// //       console.error(error);
// //     }
// //   };
// //   const handleOrderDelete = (id) => {
// //     const authToken = localStorage.getItem("token");
// //     axios
// //       .delete(`${URL}/api/deleteorder/${id}`, {
// //         headers: {
// //           Authorization: `Bearer ${authToken}`,
// //         },
// //       })
// //       .then((response) => {
// //         // Remove the deleted row from the state
// //         setOrders(orders.filter((row) => row._id !== id));
// //       })
// //       .catch((error) => {
// //         // Handle error
// //         console.error("Error deleting order:", error);
// //       });
// //   };

// //   return (
// //     <div>
// //       {/* <a href="https://webpixels.io/components?ref=codepen" className="btn w-full btn-primary text-truncate rounded-0 py-2 border-0 position-relative" style={{zIndex: 1000}}>
// //     <strong>Crafted with Webpixels CSS:</strong>
// //   </a> */}
// //       <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
// //         <nav
// //           className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
// //           id="navbarVertical"
// //         >
// //           <div className="container-fluid">
// //             <button
// //               className="navbar-toggler ms-n2"
// //               type="button"
// //               data-bs-toggle="collapse"
// //               data-bs-target="#sidebarCollapse"
// //               aria-controls="sidebarCollapse"
// //               aria-expanded="false"
// //               aria-label="Toggle navigation"
// //             >
// //               <span className="navbar-toggler-icon" />
// //             </button>
// //             {/* <Link className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" to={"/books"}>
// //             <Typography
// //               variant='h6'
// //               noWrap
// //               sx={{
// //                 mr: 2,
// //                 display: { xs: "none", md: "flex" },
// //                 fontFamily: "lato",
// //                 fontWeight: 700,
// //                 letterSpacing: ".1rem",
// //                 textTransform: "none",
// //                 color: "inherit",
// //                 textDecoration: "0",
// //                 // fontStyle: "italic",
// //                 fontFamily: "Poppins",
// //                 marginTop: 2,
// //                 marginRight: "0",
// //               }}
// //             >
// //               Simply Memoirs
// //             </Typography>
// //           </Link> */}
// //             <div className="navbar-user d-lg-none">
// //               <div className="dropdown">
// //                 <a
// //                   href="#"
// //                   id="sidebarAvatar"
// //                   role="button"
// //                   data-bs-toggle="dropdown"
// //                   aria-haspopup="true"
// //                   aria-expanded="false"
// //                 >
// //                   <div className="avatar-parent-child">
// //                     <img
// //                       alt="Image Placeholder"
// //                       src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
// //                       className="avatar avatar- rounded-circle"
// //                     />
// //                     <span className="avatar-child avatar-badge bg-success" />
// //                   </div>
// //                 </a>
// //                 <div
// //                   className="dropdown-menu dropdown-menu-end"
// //                   aria-labelledby="sidebarAvatar"
// //                 >
// //                   <a href="#" className="dropdown-item">
// //                     Profile
// //                   </a>
// //                   <a href="#" className="dropdown-item">
// //                     Settings
// //                   </a>
// //                   <a href="#" className="dropdown-item">
// //                     Billing
// //                   </a>
// //                   <hr className="dropdown-divider" />
// //                 </div>
// //               </div>
// //             </div>
// //             <div class="collapse navbar-collapse" id="sidebarCollapse">
// //               <ul class="navbar-nav flex-column">
// //                 <li class="nav-item">
// //                   <a
// //                     class="nav-link d-flex align-items-center"
// //                     href="#"
// //                     onclick="showUsers()"
// //                   >
// //                     <i class="bi bi-house me-2"></i>
// //                     <span class="nav-text">Users</span>
// //                   </a>
// //                 </li>
// //                 <li class="nav-item">
// //                   <a
// //                     class="nav-link d-flex align-items-center"
// //                     href="#"
// //                     onclick="showAdmins()"
// //                   >
// //                     <i class="bi bi-person-square me-2"></i>
// //                     <span class="nav-text">Admins</span>
// //                   </a>
// //                 </li>
// //                 <li class="nav-item">
// //                   <a
// //                     class="nav-link d-flex align-items-center"
// //                     href="#"
// //                     onclick="showOrders()"
// //                   >
// //                     <i class="bi bi-receipt me-2"></i>
// //                     <span class="nav-text">Orders</span>
// //                   </a>
// //                 </li>
// //                 <li class="nav-item">
// //                   <a
// //                     class="nav-link d-flex align-items-center"
// //                     href="#"
// //                     onclick="showShipments()"
// //                   >
// //                     <i class="bi bi-receipt me-2"></i>
// //                     <span class="nav-text">Shipments</span>
// //                   </a>
// //                 </li>
// //                 <li class="nav-item">
// //                   <a
// //                     class="nav-link d-flex align-items-center"
// //                     href="#"
// //                     onclick="showInvoices()"
// //                   >
// //                     <i class="bi bi-receipt me-2"></i>
// //                     <span class="nav-text">Invoices</span>
// //                   </a>
// //                 </li>
// //               </ul>
// //             </div>
// //           </div>
// //         </nav>
// //         <div className="h-screen flex-grow-1 overflow-y-lg-auto">
// //           <header className="bg-surface-primary border-bottom pt-6">
// //             <div className="container-fluid">
// //               <div className="mb-npx">
// //                 <div className="row align-items-center">
// //                   <div className="col-sm-6 col-12 mb-4 mb-sm-0">
// //                     <h1 className="h2 mb-0 ls-tight">Application</h1>
// //                   </div>
// //                   <div className="col-sm-6 col-12 text-sm-end">
// //                     <div className="mx-n1">
// //                       <a
// //                         href="#"
// //                         className="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
// //                       >
// //                         <span className=" pe-2">
// //                           <i className="bi bi-pencil" />
// //                         </span>
// //                         <span>Edit</span>
// //                       </a>
// //                       <a
// //                         href="#"
// //                         className="btn d-inline-flex btn-sm btn-primary mx-1"
// //                       >
// //                         <span className=" pe-2">
// //                           <i className="bi bi-plus" />
// //                         </span>
// //                         <span>Create</span>
// //                       </a>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 {/* <ul className="nav nav-tabs mt-4 overflow-x border-0">
// //                   <li className="nav-item ">
// //                     <a href="#" className="nav-link active">
// //                       All files
// //                     </a>
// //                   </li>
// //                   <li className="nav-item">
// //                     <a href="#" className="nav-link font-regular">
// //                       Shared
// //                     </a>
// //                   </li>
// //                   <li className="nav-item">
// //                     <a href="#" className="nav-link font-regular">
// //                       File requests
// //                     </a>
// //                   </li>
// //                 </ul> */}
// //               </div>
// //             </div>
// //           </header>
// //           <main className="py-6 bg-surface-secondary">
// //             <div className="container-fluid">
// //               {showTable ? (
// //                 <UserTable
// //                   users={users}
// //                   handleAdmin={handleAdmin}
// //                   handleDelete={handleDelete}
// //                 />
// //               ) : null}
// //               {showAdminContent ? (
// //                 <AdminTable admins={admins} handleDelete={handleDelete} />
// //               ) : null}
// //               {showOrdersContent ? (
// //                 <OrdersTable
// //                   orders={orders}
// //                   handleOrderDelete={handleOrderDelete}
// //                   updateOrders={updateOrders}
// //                 />
// //               ) : null}
// //               {showShipmentTable ? <ShipmentTable orders={orders} /> : null}
// //               {showInvoicesTable ? <InvoicesTable payments={payments} /> : null}
// //             </div>
// //           </main>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }
// // export default SuperAdminPanel;

// import "../App.css";
// import * as React from "react";
// import { useState, useEffect } from "react";
// import UserTable from "./UserTable";
// import AdminTable from "./AdminTable";
// import { styled } from "@mui/material/styles";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableRow from "@mui/material/TableRow";
// import axios from "axios";
// import OrdersTable from "./Orderstable";
// import InvoicesTable from "./InvoicesTable";
// import ShipmentTable from "./ShipmentTable";

// // Styled Table Cells with a gradient background for the header
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // background: "linear-gradient(to right, #c, #4a00e0)", // Purple gradient background for table header
//     color: theme.palette.common.white, // White text color
//     background: "#8e2de2",
//     padding: "16px",
//     fontFamily: "Poppins, sans-serif",
//     fontWeight: "bold",
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     fontFamily: "Poppins, sans-serif",
//     // color: "#333333", // Consistent text color
//     background: "#8e2de2",
//     padding: "15px",
//   },
// }));

// // Styled Table Rows with alternating background and hover effect
// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "#f9f9f9", // Light gray for odd rows
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: "#ffffff", // White for even rows
//   },
//   "&:hover": {
//     backgroundColor: "#8e2de2 !important", // Light background on hover
//     color: "#ffff",
//   },
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// function SuperAdminPanel() {
//   const [orders, setOrders] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [admins, setAdmins] = useState([]);
//   const [payments, setPayments] = useState([]);
//   const [showTable, setShowTable] = useState(true);
//   const [showAdminContent, setShowAdminContent] = useState(false);
//   const [showOrdersContent, setShowOrdersContent] = useState(false);
//   const [showShipmentTable, setShowShipmentTable] = useState(false);
//   const [showInvoicesTable, setshowInvoicesTable] = useState(false);

//   const URL = "https://app.simplymemoirs.com";
//   const authToken = localStorage.getItem("token");

//   const showUsers = () => {
//     setShowTable(true);
//     setShowAdminContent(false);
//     setShowOrdersContent(false);
//     setshowInvoicesTable(false);
//     setShowShipmentTable(false);
//   };

//   const showAdmins = () => {
//     setShowTable(false);
//     setShowAdminContent(true);
//     setShowOrdersContent(false);
//     setshowInvoicesTable(false);
//     setShowShipmentTable(false);
//   };

//   const showOrders = () => {
//     setShowTable(false);
//     setShowAdminContent(false);
//     setShowOrdersContent(true);
//     setshowInvoicesTable(false);
//     setShowShipmentTable(false);
//   };

//   const showShipments = () => {
//     setShowTable(false);
//     setShowAdminContent(false);
//     setShowOrdersContent(false);
//     setshowInvoicesTable(false);
//     setShowShipmentTable(true);
//   };

//   const showInvoices = () => {
//     setShowTable(false);
//     setShowAdminContent(false);
//     setShowOrdersContent(false);
//     setshowInvoicesTable(true);
//     setShowShipmentTable(false);
//   };

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await axios.get(`${URL}/api/allusers`, {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         });
//         setUsers(response.data.users);
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchUsers();
//     fetchAdmins();
//   }, []);

//   useEffect(() => {
//     fetchOrders();
//     fetchPayments();
//   }, []);

//   const fetchPayments = async () => {
//     try {
//       const response = await axios.get(`${URL}/api/getallpayments`);
//       setPayments(response.data);
//     } catch (error) {
//       console.error("Error fetching payments:", error);
//     }
//   };

//   const fetchOrders = async () => {
//     try {
//       const response = await axios.get(`${URL}/api/getallorders`, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       });
//       setOrders(response.data);
//     } catch (error) {
//       console.error("Error fetching orders:", error);
//     }
//   };

//   const updateOrders = () => {
//     fetchOrders();
//   };

//   const fetchAdmins = async () => {
//     try {
//       const response = await axios.get(`${URL}/api/alladmins`, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       });
//       setAdmins(response.data.users);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleAdmin = async (userId) => {
//     try {
//       const response = await axios.patch(
//         `${URL}/api/makeAdmin/${userId}`,
//         null,
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         setUsers((prevUsers) =>
//           prevUsers.filter((user) => user._id !== userId)
//         );
//         fetchAdmins();
//       }
//     } catch (error) {
//       console.error("Error updating user role:", error);
//     }
//   };

//   const handleDelete = async (userId) => {
//     try {
//       const response = await axios.delete(`${URL}/api/userdelete/${userId}`, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       });

//       if (response.data.success) {
//         setUsers((prevUsers) =>
//           prevUsers.filter((user) => user._id !== userId)
//         );
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleOrderDelete = (id) => {
//     axios
//       .delete(`${URL}/api/deleteorder/${id}`, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       })
//       .then(() => {
//         setOrders(orders.filter((row) => row._id !== id));
//       })
//       .catch((error) => {
//         console.error("Error deleting order:", error);
//       });
//   };

//   return (
//     <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
//       <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg">
//         <div className="container-fluid">
//           <button
//             className="navbar-toggler ms-n2"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#sidebarCollapse"
//             aria-controls="sidebarCollapse"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span className="navbar-toggler-icon" />
//           </button>
//           <div className="collapse navbar-collapse" id="sidebarCollapse">
//             <ul className="navbar-nav flex-column">
//               <li className="nav-item">
//                 <a className="nav-link" href="#" onClick={showUsers}>
//                   <i
//                     className="bi bi-house me-2"
//                     style={{ color: "#6a1ac3" }}
//                   ></i>
//                   <span className="nav-text">Users</span>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" href="#" onClick={showAdmins}>
//                   <i
//                     className="bi bi-person-square me-2"
//                     style={{ color: "#6a1ac3" }}
//                   ></i>
//                   <span className="nav-text">Admins</span>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" href="#" onClick={showOrders}>
//                   <i
//                     className="bi bi-receipt me-2"
//                     style={{ color: "#6a1ac3" }}
//                   ></i>
//                   <span className="nav-text">Orders</span>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" href="#" onClick={showShipments}>
//                   <i
//                     className="bi bi-box me-2"
//                     style={{ color: "#6a1ac3" }}
//                   ></i>
//                   <span className="nav-text">Shipments</span>
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" href="#" onClick={showInvoices}>
//                   <i
//                     className="bi bi-file-earmark-text me-2"
//                     style={{ color: "#6a1ac3" }}
//                   ></i>
//                   <span className="nav-text">Invoices</span>
//                 </a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>

//       <div className="h-screen flex-grow-1 overflow-y-lg-auto">
//         <header className="bg-surface-primary border-bottom pt-6">
//           <div className="container-fluid">
//             <div className="row align-items-center">
//               <div className="col-sm-6 col-12 mb-4 mb-sm-0">
//                 <h1 className="h2 mb-0 ls-tight" style={{ color: "#6a1ac3" }}>
//                   Admin Dashboard
//                 </h1>
//               </div>
//               <div className="col-sm-6 col-12 text-sm-end">
//                 <div className="mx-n1">
//                   <a
//                     href="#"
//                     className="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
//                   >
//                     <span className="pe-2">
//                       <i className="bi bi-pencil" />
//                     </span>
//                     <span>Edit</span>
//                   </a>
//                   <a
//                     href="#"
//                     className="btn d-inline-flex btn-sm btn-primary mx-1"
//                     style={{ backgroundColor: "#6a1ac3" }}
//                   >
//                     <span className="pe-2">
//                       <i className="bi bi-plus" />
//                     </span>
//                     <span>Create</span>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </header>

//         <main className="py-6 bg-surface-secondary">
//           <div className="container-fluid">
//             {showTable ? (
//               <UserTable
//                 users={users}
//                 handleAdmin={handleAdmin}
//                 handleDelete={handleDelete}
//               />
//             ) : null}
//             {showAdminContent ? (
//               <AdminTable admins={admins} handleDelete={handleDelete} />
//             ) : null}
//             {showOrdersContent ? (
//               <OrdersTable
//                 orders={orders}
//                 handleOrderDelete={handleOrderDelete}
//                 updateOrders={updateOrders}
//               />
//             ) : null}
//             {showShipmentTable ? <ShipmentTable orders={orders} /> : null}
//             {showInvoicesTable ? <InvoicesTable payments={payments} /> : null}
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// }

// export default SuperAdminPanel;

import "../App.css";
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import axios from "axios";
import OrdersTable from "./Orderstable";
import InvoicesTable from "./InvoicesTable";
import ShipmentTable from "./ShipmentTable";

// Styled Table Cells with consistent color scheme
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a1ac3", // Primary purple color for table header
    color: "#ffffff", // White text for the header
    padding: "20px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    color: "#333333", // Consistent text color
    padding: "15px",
  },
  "&:hover": {
    color: "#ffffff", // Change text color to white on hover
    backgroundColor: "#a55fe8", // Optional: Change background on hover
  },
}));

// Styled Table Rows with alternating background
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9", // Light gray for odd rows
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff", // White for even rows
  },
  "&:hover": {
    backgroundColor: "#a55fe8", // Hover effect with the light purple
    color: "#FFFF",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SuperAdminPanel() {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showOrdersContent, setShowOrdersContent] = useState(false);
  const [showShipmentTable, setShowShipmentTable] = useState(false);
  const [showInvoicesTable, setshowInvoicesTable] = useState(false);
  const authToken = localStorage.getItem("token");
  const URL = "https://app.simplymemoirs.com";

  const showUsers = () => {
    setShowTable(true);
    setShowOrdersContent(false);
    setshowInvoicesTable(false);
    setShowShipmentTable(false);
  };
  const showOrders = () => {
    setShowTable(false);
    setShowOrdersContent(true);
    setshowInvoicesTable(false);
    setShowShipmentTable(false);
  };
  const showShipments = () => {
    setShowTable(false);
    setShowOrdersContent(false);
    setshowInvoicesTable(false);
    setShowShipmentTable(true);
  };
  const showInvoices = () => {
    setShowTable(false);
    setShowOrdersContent(false);
    setshowInvoicesTable(true);
    setShowShipmentTable(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${URL}/api/allusers`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchPayments();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${URL}/api/getallorders`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await axios.get(`${URL}/api/getallpayments`);
      setPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await axios.delete(`${URL}/api/userdelete/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user._id !== userId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderDelete = (id) => {
    axios
      .delete(`${URL}/api/deleteorder/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(() => {
        setOrders(orders.filter((row) => row._id !== id));
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
      });
  };

  return (
    <div className="admin-panel">
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-end-lg">
          <div className="container-fluid">
            <button
              className="navbar-toggler ms-n2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="navbar-user d-lg-none">
              <div className="dropdown">
                <a
                  href="#"
                  id="sidebarAvatar"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar-parent-child">
                    <img
                      alt="User"
                      src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                      className="avatar avatar-rounded-circle"
                    />
                    <span className="avatar-child avatar-badge bg-success" />
                  </div>
                </a>
              </div>
            </div>

            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showUsers}
                    style={{ color: "#6a1ac3" }} // Primary color
                  >
                    <i
                      className="bi bi-house me-2"
                      style={{ color: "#6a1ac3" }}
                    ></i>
                    <span>Users</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showOrders}
                    style={{ color: "#6a1ac3" }} // Primary color
                  >
                    <i
                      className="bi bi-receipt me-2"
                      style={{ color: "#6a1ac3" }}
                    ></i>
                    <span>Orders</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showOrders}
                    style={{ color: "#6a1ac3" }} // Primary color
                  >
                    <i
                      className="bi bi-receipt me-2"
                      style={{ color: "#6a1ac3" }}
                    ></i>
                    <span>Admin</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showShipments}
                    style={{ color: "#6a1ac3" }} // Primary color
                  >
                    <i
                      className="bi bi-box-seam me-2"
                      style={{ color: "#6a1ac3" }}
                    ></i>
                    <span>Shipments</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showInvoices}
                    style={{ color: "#6a1ac3" }} // Primary color
                  >
                    <i
                      className="bi bi-file-earmark-text me-2"
                      style={{ color: "#6a1ac3" }}
                    ></i>
                    <span>Invoices</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container-fluid">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                    <h1
                      className="h2 mb-0 ls-tight"
                      style={{ color: "#6a1ac3" }}
                    >
                      Admin Panel
                    </h1>{" "}
                    {/* Purple heading */}
                  </div>
                  <div className="col-sm-6 col-12 text-sm-end">
                    <div className="mx-n1">
                      <a
                        href="#"
                        className="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
                      >
                        <span className="pe-2">
                          <i className="bi bi-pencil" />
                        </span>
                        <span>Edit</span>
                      </a>
                      <a
                        href="#"
                        className="btn d-inline-flex btn-sm btn-primary mx-1"
                        style={{ backgroundColor: "#6a1ac3" }} // Primary color for button
                      >
                        <span className="pe-2">
                          <i className="bi bi-plus" />
                        </span>
                        <span>Create</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              {showTable ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>User Role</StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((row) => (
                        <StyledTableRow key={row._id}>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.email}</StyledTableCell>
                          <StyledTableCell>{row.userRole}</StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              onClick={() => handleDelete(row._id)}
                              color="inherit"
                            >
                              <DeleteOutlineIcon color="error" />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
              {showOrdersContent ? (
                <OrdersTable
                  orders={orders}
                  handleOrderDelete={handleOrderDelete}
                />
              ) : null}
              {showShipmentTable ? <ShipmentTable orders={orders} /> : null}
              {showInvoicesTable ? <InvoicesTable payments={payments} /> : null}
            </div>
          </main>
        </div>
      </div>

      <style jsx>{`
        .nav-link:hover {
          background-color: #a55fe8 !important; /* Light purple background on hover */
          color: white !important; /* White text on hover */
        }
        .nav-link:hover i {
          color: white !important; /* White icon on hover */
        }
      `}</style>
    </div>
  );
}

export default SuperAdminPanel;
