import React, { useState } from "react";
import { useParams } from "react-router-dom";
import deluxe from "../../images/deluxe.png";
import economy from "../../images/economy.png";
import hardcover from "../../images/hardcover.png";
import portrait from "../../images/download-ebook-svgrepo-com.svg";
import large from "../../images/large.png";
import medium from "../../images/medium.png";
import landscape from "../../images/drawing-book-svgrepo-com.svg";
import premium from "../../images/premium.png";
import small from "../../images/small.png";
import softcover from "../../images/softcover.png";
import standard from "../../images/standard.png";
import PrintPreview from "../MobilePreview/PrintPreview";

export const BookTypeDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("bookType", divId === 1 ? "Soft Cover" : "Hard Cover", price);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Book Type Detail
      </h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 20)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid lightgray",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            margin: "10px",
            borderRadius: "10px",
            boxShadow:
              selectedDiv === 1
                ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                : "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
          }}
        >
          <img
            src={softcover}
            alt="Soft Cover"
            style={{ width: "150px", height: "150px", objectFit: "cover" }}
          />
          <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
            Soft Cover
          </p>
          <p style={{ fontStyle: "italic", margin: "0" }}>
            Price: <span style={{ fontWeight: "bold" }}>$20</span>
          </p>
        </div>

        <div
          onClick={() => handleDivClick(2, 25)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid lightgray",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            margin: "10px",
            borderRadius: "10px",
            boxShadow:
              selectedDiv === 2
                ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                : "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s",
          }}
        >
          <img
            src={hardcover}
            alt="Hard Cover"
            style={{ width: "150px", height: "150px", objectFit: "cover" }}
          />
          <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
            Hard Cover
          </p>
          <p style={{ fontStyle: "italic", margin: "0" }}>
            Price: <span style={{ fontWeight: "bold" }}>$25</span>
          </p>
        </div>
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Soft covers provide a more flexible and lightweight reading
          experience. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Quisque vehicula quam in volutpat pellentesque. Proin non magna vel
          erat fermentum bibendum. Nulla facilisi.
        </p>
      )}

      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Hard covers offer greater durability and a premium look. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Donec suscipit ante vel
          enim fermentum, non ultricies odio placerat. Etiam vitae arcu id urna
          dictum suscipit.
        </p>
      )}
    </div>
  );
};

export const PrintQualityDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("printQuality", divId === 1 ? "Economy" : "Deluxe", price);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Print Quality Detail
      </h5>
      <div style={{ display: "flex" }}>
        {["Economy", "Deluxe"].map((quality, index) => (
          <div
            key={quality}
            onClick={() => handleDivClick(index + 1, index === 0 ? 5 : 8)}
            style={{
              border:
                selectedDiv === index + 1
                  ? "2px solid blue"
                  : "2px solid lightgray",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px",
              margin: "10px",
              borderRadius: "10px",
              boxShadow:
                selectedDiv === index + 1
                  ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
          >
            <img
              src={index === 0 ? economy : deluxe}
              alt={`${quality} Quality`}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
              {quality}
            </p>
            <p style={{ fontStyle: "italic", margin: "0" }}>
              Price:{" "}
              <span style={{ fontWeight: "bold" }}>${index === 0 ? 5 : 8}</span>
            </p>
          </div>
        ))}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Economy print quality is a cost-effective option, providing good
          quality at an affordable price. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Sed vulputate, orci in viverra pharetra,
          justo libero facilisis nisi, vel malesuada massa orci in turpis.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Deluxe print quality offers premium paper and ink for an enhanced
          print experience. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Nulla ornare metus ac arcu fermentum, ut tincidunt quam cursus.
          Suspendisse potenti.
        </p>
      )}
    </div>
  );
};

export const OrientationDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange("orientation", divId === 1 ? "Portrait" : "Landscape", price);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Orientation Detail
      </h5>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => handleDivClick(1, 5)}
          style={{
            border:
              selectedDiv === 1 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={portrait}
            alt="Portrait"
            style={{ width: "100px", height: "150px" }}
          />
          <p style={{ fontWeight: "600" }}>Portrait</p>
          <p style={{ fontFamily: "italic" }}>
            Price:<span style={{ fontWeight: "bold" }}>$5</span>
          </p>
        </div>

        <div
          onClick={() => handleDivClick(2, 5)}
          style={{
            border:
              selectedDiv === 2 ? "2px solid blue" : "2px solid transparent",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px",
            borderRadius: "10px",
          }}
        >
          <img
            src={landscape}
            alt="Landscape"
            style={{ width: "150px", height: "100px", marginTop: "30px" }}
          />
          <p style={{ fontWeight: "600" }}>Landscape</p>
          <p style={{ fontFamily: "italic" }}>
            Price: <span style={{ fontWeight: "bold" }}>$5</span>
          </p>
        </div>
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Portrait orientation is ideal for content that is taller than it
          is wide, such as books and posters. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Vivamus consequat, lacus et egestas
          tincidunt, velit quam tincidunt mi, non vehicula velit nulla in velit.
        </p>
      )}

      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Landscape orientation works best for content that is wider than it
          is tall, such as calendars and scenic photographs. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Aenean blandit, erat in
          hendrerit sollicitudin, dolor purus aliquet ligula, vel efficitur
          sapien libero ut sapien.
        </p>
      )}
    </div>
  );
};

export const BookSizeDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    let size = ["6x9", "8x10", "8.5x11"][divId - 1];
    onChange("bookSize", size, price);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Book Size Detail
      </h5>
      <div style={{ display: "flex" }}>
        {["6x9", "8x10", "8.5x11"].map((size, index) => (
          <div
            key={size}
            onClick={() => handleDivClick(index + 1, [20, 30, 40][index])}
            style={{
              border:
                selectedDiv === index + 1
                  ? "2px solid blue"
                  : "2px solid lightgray",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px",
              margin: "10px",
              borderRadius: "10px",
              boxShadow:
                selectedDiv === index + 1
                  ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
          >
            <img
              src={index === 0 ? small : index === 1 ? medium : large}
              alt={`${size}`}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>{size}</p>
            <p style={{ fontStyle: "italic", margin: "0" }}>
              Price:{" "}
              <span style={{ fontWeight: "bold" }}>${[20, 30, 40][index]}</span>
            </p>
          </div>
        ))}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The 6x9 size is perfect for novels and most non-fiction books. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum velit
          at libero consequat, in aliquam erat fermentum. Vivamus tempor nec
          nisi ut scelerisque.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The 8x10 size is great for workbooks, manuals, or illustrated books.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          venenatis, erat a hendrerit iaculis, lectus ligula cursus nisi, vitae
          malesuada justo odio quis est.
        </p>
      )}
      {selectedDiv === 3 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The 8.5x11 size is commonly used for textbooks and other larger books
          that require more space. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Sed tristique purus id magna consequat, nec dignissim
          nisi viverra.
        </p>
      )}
    </div>
  );
};

export const PaperWeightDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "paperWeight",
      divId === 1 ? "80lb - 444 pages/inch" : "100lb - 376 pages/inch",
      price
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Paper Weight Detail
      </h5>
      <div style={{ display: "flex" }}>
        {["80lb - 444 pages/inch", "100lb - 376 pages/inch"].map(
          (weight, index) => (
            <div
              key={weight}
              onClick={() => handleDivClick(index + 1, index === 0 ? 4 : 5)}
              style={{
                border:
                  selectedDiv === index + 1
                    ? "2px solid blue"
                    : "2px solid lightgray",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "15px",
                margin: "10px",
                borderRadius: "10px",
                boxShadow:
                  selectedDiv === index + 1
                    ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                    : "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
              }}
            >
              <img
                src={index === 0 ? standard : premium}
                alt={`${weight}`}
                style={{ width: "150px", height: "150px", objectFit: "cover" }}
              />
              <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
                {weight}
              </p>
              <p style={{ fontStyle: "italic", margin: "0" }}>
                Price:{" "}
                <span style={{ fontWeight: "bold" }}>
                  ${index === 0 ? 4 : 5}
                </span>
              </p>
            </div>
          )
        )}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The 80lb paper weight offers a balance between quality and
          flexibility, making it ideal for most books. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Proin auctor lorem sit amet libero
          fermentum, vel aliquet erat facilisis. Nulla facilisi.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The 100lb paper weight provides a premium feel with added durability,
          making it great for high-quality prints. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Curabitur id tincidunt turpis. Vestibulum
          dignissim eros vel purus viverra tincidunt.
        </p>
      )}
    </div>
  );
};

export const DeliveryMethodDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "deliveryMethod",
      divId === 1 ? "Standard Delivery" : "Express Delivery",
      price
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>
        Delivery Method Detail
      </h5>
      <div style={{ display: "flex" }}>
        {["Standard Delivery", "Express Delivery"].map((method, index) => (
          <div
            key={method}
            onClick={() => handleDivClick(index + 1, index === 0 ? 5 : 15)}
            style={{
              border:
                selectedDiv === index + 1
                  ? "2px solid blue"
                  : "2px solid lightgray",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px",
              margin: "10px",
              borderRadius: "10px",
              boxShadow:
                selectedDiv === index + 1
                  ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
          >
            <img
              src={
                index === 0
                  ? "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/image.png-79"
                  : "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/image.png-86"
              }
              alt={method}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
              {method}
            </p>
            <p style={{ fontStyle: "italic", margin: "0" }}>
              Price:{" "}
              <span style={{ fontWeight: "bold" }}>
                ${index === 0 ? 5 : 10}
              </span>
            </p>
          </div>
        ))}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Standard Delivery offers an economical choice, typically taking 5-7
          business days. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Fusce a quam non dui gravida pretium. Nulla ac ligula velit.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Express Delivery ensures your items arrive within 1-3 business days,
          ideal for urgent needs. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Integer euismod nec massa sed tristique. Aliquam erat
          volutpat.
        </p>
      )}
    </div>
  );
};

export const CoverFinishDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    onChange(
      "coverFinish",
      divId === 1 ? "Matte Finish" : "Gloss Finish",
      price
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>Cover Finish</h5>
      <div style={{ display: "flex" }}>
        {["Matte Finish", "Gloss Finish"].map((finish, index) => (
          <div
            key={finish}
            onClick={() => handleDivClick(index + 1, index === 0 ? 5 : 10)}
            style={{
              border:
                selectedDiv === index + 1
                  ? "2px solid blue"
                  : "2px solid lightgray",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px",
              margin: "10px",
              borderRadius: "10px",
              boxShadow:
                selectedDiv === index + 1
                  ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
          >
            <img
              src={
                index === 0
                  ? "https://5.imimg.com/data5/VM/YG/SQ/SELLER-29267953/sand-mat-finish-book-binding-polypropylene-sheets-500x500.jpg"
                  : "https://simplymemoirs.s3.us-west-1.amazonaws.com/images/image.png-87"
              }
              alt={`${finish}`}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
              {finish}
            </p>
            <p style={{ fontStyle: "italic", margin: "0" }}>
              Price:{" "}
              <span style={{ fontWeight: "bold" }}>
                ${index === 0 ? 5 : 10}
              </span>
            </p>
          </div>
        ))}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Matte Finish provides a smooth, glare-free surface that gives your
          book a professional and elegant look. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Phasellus ultricies neque nec libero
          convallis, ut luctus ligula interdum.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          Gloss Finish offers a shiny, reflective surface that enhances colors
          and provides a vibrant look. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Vestibulum lacinia, nisi in hendrerit luctus, lectus
          erat vulputate massa, nec finibus libero mauris eget sapien.
        </p>
      )}
    </div>
  );
};

export const BookWeightDetail = ({ onChange }) => {
  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleDivClick = (divId, price) => {
    setSelectedDiv(divId);
    let size = "";
    switch (divId) {
      case 1:
        size = "Small";
        break;
      case 2:
        size = "Medium";
        break;
      case 3:
        size = "Large";
        break;
      default:
        size = "";
    }
    onChange("bookWeight", size, price);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>Book Weight</h5>
      <div style={{ display: "flex" }}>
        {["Small", "Medium", "Large"].map((weight, index) => (
          <div
            key={weight}
            onClick={() =>
              handleDivClick(index + 1, [0.7452, 1.104, 1.293][index])
            }
            style={{
              border:
                selectedDiv === index + 1
                  ? "2px solid blue"
                  : "2px solid lightgray",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px",
              margin: "10px",
              borderRadius: "10px",
              boxShadow:
                selectedDiv === index + 1
                  ? "0 4px 8px rgba(0, 0, 255, 0.2)"
                  : "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
          >
            <img
              src={index === 0 ? small : index === 1 ? medium : large}
              alt={`${weight} Weight`}
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>
              {weight}
            </p>
          </div>
        ))}
      </div>
      {selectedDiv === 1 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Small book weight is easy to carry and perfect for travel-sized
          editions. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Nulla accumsan, felis nec egestas tempus, justo risus laoreet lectus,
          non accumsan lorem eros in nisl.
        </p>
      )}
      {selectedDiv === 2 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Medium book weight provides a balance between portability and
          content volume, ideal for general use. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Sed at dolor vitae est laoreet placerat
          ac in velit.
        </p>
      )}
      {selectedDiv === 3 && (
        <p
          style={{
            textAlign: "center",
            marginTop: "15px",
            fontSize: "0.9rem",
            lineHeight: "1.5",
            color: "#333",
            maxWidth: "500px",
          }}
        >
          The Large book weight is ideal for comprehensive content, suitable for
          reference books or large collections. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Curabitur congue, purus vel tincidunt
          scelerisque, justo ligula facilisis sapien, in sodales odio orci non
          quam.
        </p>
      )}
    </div>
  );
};

export const CopiesDetails = ({ onChange }) => {
  const { id } = useParams();
  const [noofcopies, setNoofcopies] = useState(""); // Default value set to 1

  const handleInputChange = (type, value) => {
    if (type === "noofcopies") {
      setNoofcopies(value);
      onChange(type, value);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px 20px", // Extra padding for spacious layout
        // minHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#ffffff", // White background for the card
          borderRadius: "16px", // Larger border-radius for a more modern look
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)", // Soft, larger box shadow for elevation
          padding: "40px",
          width: "100%",
        }}
      >
        <h3
          style={{
            textAlign: "center",
            marginBottom: "24px",
            color: "#222", // Darker color for better contrast
            fontWeight: "bold",
            fontSize: "24px", // Slightly larger heading
          }}
        >
          Copies Details
        </h3>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <label
              style={{
                marginBottom: "8px",
                fontSize: "16px",
                fontWeight: "600", // Bolder font for label
                color: "#333", // Darker color for label text
              }}
            >
              Number of Copies
            </label>
            <input
              type="number"
              value={noofcopies}
              min="8"
              placeholder="Number of copies must be greater than 8"
              onChange={(e) => handleInputChange("noofcopies", e.target.value)}
              style={{
                width: "100%",
                padding: "14px", // Increased padding for better input interaction
                border: "1px solid #ccc", // Subtle border color for input
                borderRadius: "8px", // Smooth border radius for input
                fontSize: "16px",
                boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)", // Subtle shadow for better depth
                outline: "none",
                transition: "border-color 0.2s, box-shadow 0.2s", // Smooth transitions for focus
              }}
              onFocus={
                (e) => (e.target.style.borderColor = "#1f1f1f") // Highlight input on focus
              }
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PagesColorDetail = ({ onChange, selectedPageSize }) => {
  const [printModes, setPrintModes] = useState({});

  const handleInputChange = (type, value) => {
    if (type === "printModes") {
      onChange(type, printModes);
    }
  };
  console.log(printModes);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "5px",
        transform: "scale(0.6)",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>Page Color</h5>
      <div style={{ display: "flex" }}>
        <PrintPreview
          selectedPageSize={selectedPageSize}
          showButton={false}
          showRadio={true}
          printModes={printModes}
          setPrintModes={setPrintModes}
          handleInputChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export const EpubDetail = ({ onChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onChange(
      "epub",
      option === "buy" ? "Buy ePub" : "Don't Buy",
      option === "buy" ? 40 : 0
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <h5 style={{ textAlign: "center", fontStyle: "italic" }}>ePub Options</h5>
      <img
        src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/Screenshot%202024-11-17%20at%209.05.56%20PM.png-52"
        alt="ePub"
        style={{
          width: "300px",
          height: "300px",
          marginBottom: "20px",
          borderRadius: "10px",
          objectFit: "cover",
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <button
          onClick={() => handleOptionSelect("buy")}
          style={{
            padding: "10px 20px",
            border:
              selectedOption === "buy" ? "2px solid blue" : "1px solid gray",
            borderRadius: "5px",
            backgroundColor: selectedOption === "buy" ? "#f0f8ff" : "#fff",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          Buy ePub
        </button>
        <button
          onClick={() => handleOptionSelect("dontBuy")}
          style={{
            padding: "10px 20px",
            border:
              selectedOption === "dontBuy"
                ? "2px solid blue"
                : "1px solid gray",
            borderRadius: "5px",
            backgroundColor: selectedOption === "dontBuy" ? "#f0f8ff" : "#fff",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          Don't Buy
        </button>
      </div>
      {selectedOption === "buy" && (
        <p style={{ marginTop: "20px", color: "#333", fontStyle: "italic" }}>
          You have selected to purchase the ePub version for $40.
        </p>
      )}
      {selectedOption === "dontBuy" && (
        <p style={{ marginTop: "20px", color: "#333", fontStyle: "italic" }}>
          You have opted not to purchase the ePub version.
        </p>
      )}
    </div>
  );
};
