// // ImageGrid.js
// import { Quill } from "react-quill";

// export function insertGrid(quill, rows, cols) {
//   const range = quill.getSelection();
//   if (!range) return;

//   const gridHTML = `<div class="quill-image-grid" style="display: grid; grid-template-columns: repeat(${cols}, 1fr); gap: 10px;">${Array(
//     rows * cols
//   )
//     .fill(
//       '<div class="grid-cell" style="border: 1px solid #ccc; padding: 10px; min-height: 100px;" contenteditable="false"></div>'
//     )
//     .join("")}</div>`;

//   quill.clipboard.dangerouslyPasteHTML(range.index, gridHTML);
// }

// export function handleGridImageUpload(quill, event) {
//   const file = event.target.files[0];
//   const reader = new FileReader();

//   reader.onload = function () {
//     const range = quill.getSelection();
//     const imgHTML = `<img src="${reader.result}" style="width: 100%; height: auto;" />`;
//     quill.clipboard.dangerouslyPasteHTML(range.index, imgHTML);
//   };

//   reader.readAsDataURL(file);
// }

// export function setupGridToolbar(quill) {
//   const toolbar = quill.getModule("toolbar");

//   if (!toolbar) {
//     console.error("Toolbar module not found");
//     return;
//   }

//   console.log("Toolbar initialized:", toolbar);

//   toolbar.addHandler("grid-3x3", () => {
//     console.log("Inserting 3x3 grid");
//     insertGrid(quill, 3, 3);
//   });

//   toolbar.addHandler("grid-4x4", () => {
//     console.log("Inserting 4x4 grid");
//     insertGrid(quill, 4, 4);
//   });
// }

import Quill from "quill";

const Module = Quill.import("core/module");
const BlockEmbed = Quill.import("blots/block/embed");

class GridBlot extends BlockEmbed {
  static createGrid(type, rows, cols) {
    const container = document.createElement("div");
    container.setAttribute("data-type", type);
    container.setAttribute("class", "custom-grid");

    for (let i = 0; i < rows; i++) {
      const row = document.createElement("div");
      row.setAttribute("class", "grid-row");
      for (let j = 0; j < cols; j++) {
        const cell = document.createElement("div");
        cell.setAttribute("class", "grid-cell");
        cell.setAttribute("contenteditable", "false");

        const placeholder = document.createElement("div");
        placeholder.className = "grid-placeholder";
        placeholder.innerText = "Upload Image";
        cell.appendChild(placeholder);

        row.appendChild(cell);
      }
      container.appendChild(row);
    }

    return container;
  }

  static create({ rows, cols }) {
    const gridNode = this.createGrid("custom-grid", rows, cols);
    return gridNode;
  }

  static value(domNode) {
    const gridCells = domNode.querySelectorAll(".grid-cell");
    const gridData = Array.from(gridCells).map((cell) => {
      const img = cell.querySelector("img");
      return img ? img.getAttribute("src") : null;
    });

    return gridData;
  }
}
export class CustomGridModule extends Module {
  constructor(quill, options) {
    super(quill, options);

    const toolbar = quill.getModule("toolbar");
    toolbar.addHandler("grid-3x3", () => this.insertGrid(3, 3));
    toolbar.addHandler("grid-4x4", () => this.insertGrid(4, 4));

    quill.root.addEventListener("click", this.handleClick.bind(this));
  }

  insertGrid(rows, cols) {
    const range = this.quill.getSelection(true);
    this.quill.insertEmbed(range.index, "grid", { rows, cols });
    this.quill.setSelection(range.index + 1);
  }

  handleClick(e) {
    const target = e.target;
    if (target.classList.contains("grid-placeholder")) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const img = document.createElement("img");
          img.src = reader.result;
          img.style.width = "100%";
          img.style.height = "100%";

          target.parentNode.innerHTML = ""; // Remove placeholder
          target.parentNode.appendChild(img);
        };
        reader.readAsDataURL(file);
      };
      input.click();
    }
  }
}

Quill.register("formats/grid", GridBlot);
