import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import "./BookEditor.css";
import Menu from "./Menu";
import Toolbar from "./Toolbar";
import Softcover from "./Softcover";
import Hardcover from "./Hardcover";
import { toPng } from "html-to-image";
import { uploadBookCover } from "../../redux/action/action.js";
import { uploadFrontBookCover } from "../../redux/action/action.js";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Textarea } from "@mui/joy";

const ZoomButton = styled("button")(({ theme }) => ({
  width: "80px",
  height: "80px",
  border: "none",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  margin: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const BookEditor = () => {
  const [color, setColor] = useState();
  const [textColor, setTextColor] = useState("black");
  const [zoomLevel, setZoomLevel] = useState(50);
  const [coverWidth, setCoverWidth] = useState("8.5in");
  const [coverHeight, setCoverHeight] = useState("11in");
  const [frontCoverImage, setFrontCoverImage] = useState(null);
  const [backCoverImage, setBackCoverImage] = useState(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isAddingText, setIsAddingText] = useState(false);
  const [frontZoom, setFrontZoom] = useState(1.3);
  const [backZoom, setBackZoom] = useState(1.3);
  const [textAreas, setTextAreas] = useState({ front: [], back: [] });
  const [resizingTextArea, setResizingTextArea] = useState(null);
  const [resizingOffset, setResizingOffset] = useState({ x: 0, y: 0 });
  const [selection, setSelection] = useState(null);
  const [fontSize, setFontSize] = useState("16px");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [draggingTextArea, setDraggingTextArea] = useState(null);
  const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });
  const [currentCoverType, setCurrentCoverType] = useState("softcover");
  const toolbarRef = useRef(null);
  const coverRef = useRef(null);
  const frontcoverRef = useRef(null);
  const { id: bookId } = useParams();
  const dispatch = useDispatch();
  // const { coverUrl, loading, error } = useSelector((state) => state.bookCover);

  const changeColor = (newColor) => setColor(newColor);

  const zoomIn = () => setZoomLevel((prevZoom) => prevZoom + 10);

  const zoomOut = () => {
    if (zoomLevel > 10) {
      setZoomLevel((prevZoom) => prevZoom - 10);
    }
  };

  const handleSoftCoverDimensionChange = (width, height) => {
    setCoverWidth(width);
    setCoverHeight(height);
    setCurrentCoverType("softcover");
  };

  const handleHardCoverDimensionChange = (width, height) => {
    setCoverWidth(width);
    setCoverHeight(height);
    setCurrentCoverType("hardcover");
  };

  const handleFrontCoverDrop = (event) => {
    event.preventDefault();
    const imageFile = event.dataTransfer.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setFrontCoverImage(imageUrl);
  };

  const handleBackCoverDrop = (event) => {
    event.preventDefault();
    const imageFile = event.dataTransfer.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setBackCoverImage(imageUrl);
  };

  const handleDragOver = (event) => event.preventDefault();

  const togglePreviewMode = () => setIsPreviewMode((prevMode) => !prevMode);

  const handleAddTextArea = () => setIsAddingText(true);

  const handleCoverClick = (event, coverType) => {
    if (isAddingText) {
      const newTextArea = {
        id: Date.now(),
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
        text: "",
        width: 100,
        height: 50,
        focused: true,
      };

      setTextAreas((prevTextAreas) => ({
        ...prevTextAreas,
        [coverType]: [...prevTextAreas[coverType], newTextArea],
      }));

      setIsAddingText(false);
    }
  };

  const handleTextSelect = (event) => {
    const selectedElement = event.target;
    const { top, left } = selectedElement.getBoundingClientRect();
    const selection = window.getSelection();

    // if (selection && selection.toString().length > 0) {
    setSelection({
      top: top - 40,
      left: left + window.scrollX,
      selectedElement,
    });
    // } else {
    //   setSelection(null);
    // }
  };

  const applyStyleToSelection = (style, value) => {
    const { selectedElement } = selection;
    if (selectedElement) {
      document.execCommand("styleWithCSS", false, true);
      document.execCommand(style, false, value);
      selectedElement.focus();
    }
    setSelection(null);
  };

  const handleFontSizeChange = (newSize) => {
    applyStyleToSelection("fontSize", newSize);
    setFontSize(newSize);
  };

  const handleFontFamilyChange = (newFamily) => {
    applyStyleToSelection("fontName", newFamily);
    setFontFamily(newFamily);
  };

  const handleColorChange = (newColor) => {
    applyStyleToSelection("foreColor", newColor);
    setTextColor(newColor);
  };
  const handleDragStart = (e, textArea) => {
    const rect = e.target.getBoundingClientRect();
    const containerRect = coverRef.current.getBoundingClientRect();
    setDraggingTextArea(textArea);
    setDraggingOffset({
      x: e.clientX - rect.left + containerRect.left,
      y: e.clientY - rect.top + containerRect.top,
    });
  };

  const handleDrag = (e) => {
    if (draggingTextArea) {
      const x = e.clientX - draggingOffset.x;
      const y = e.clientY - draggingOffset.y;
      setTextAreas((prevTextAreas) => ({
        ...prevTextAreas,
        [draggingTextArea.coverType]: prevTextAreas[
          draggingTextArea.coverType
        ].map((ta) => (ta.id === draggingTextArea.id ? { ...ta, x, y } : ta)),
      }));
    }
  };

  const handleDragEnd = () => {
    setDraggingTextArea(null);
  };
  const handleResizeStart = (e, textArea) => {
    e.preventDefault();
    const rect = e.target.getBoundingClientRect();
    setResizingTextArea(textArea);
    setResizingOffset({
      x: e.clientX - rect.right,
      y: e.clientY - rect.bottom,
    });
  };

  const handleResize = (e) => {
    if (resizingTextArea) {
      const width = e.clientX - resizingTextArea.x - resizingOffset.x;
      const height = e.clientY - resizingTextArea.y - resizingOffset.y;
      setTextAreas((prevTextAreas) => ({
        ...prevTextAreas,
        [resizingTextArea.coverType]: prevTextAreas[
          resizingTextArea.coverType
        ].map((ta) =>
          ta.id === resizingTextArea.id ? { ...ta, width, height } : ta
        ),
      }));
    }
  };

  const handleResizeEnd = () => {
    setResizingTextArea(null);
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", handleResizeEnd);
    return () => {
      document.removeEventListener("mousemove", handleResize);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [resizingTextArea]);
  const renderTextAreas = (coverType) => {
    return textAreas[coverType].map((textArea) => (
      <div
        key={textArea.id}
        id={textArea.id}
        data-cover-type={coverType}
        className="text-area"
        style={{
          position: "absolute",
          left: textArea.x,
          top: textArea.y,
          width: textArea.width,
          height: textArea.height,
          border: textArea.focused ? "1px solid #000" : "none",
          padding: "5px",
          outline: "none",
          fontSize: fontSize,
          fontFamily: fontFamily,
          resize: "both",
          overflow: "auto",
          width: "400px",
          height: "300px",
        }}
        contentEditable
        suppressContentEditableWarning
        onClose={() => handleTextAreaBlur(textArea.id, coverType)}
        onMouseUp={handleTextSelect}
        onFocus={(e) => {
          handleTextAreaClick(textArea.id, coverType);
          handleTextSelect(e);
        }}
        draggable
        onDragStart={(e) => handleDragStart(e, { ...textArea, coverType })}
        onDrag={(e) => handleDrag(e)}
        onDragEnd={() => handleDragEnd()}
      >
        {textArea.text}
        <div
          className="resize-handle"
          onMouseDown={(e) => handleResizeStart(e, textArea)}
        />
      </div>
    ));
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleDragEnd);
    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [draggingTextArea]);

  const handleTextAreaClick = (id, coverType) => {
    setTextAreas((prevTextAreas) => ({
      ...prevTextAreas,
      [coverType]: prevTextAreas[coverType].map((textArea) =>
        textArea.id === id
          ? { ...textArea, focused: true }
          : { ...textArea, focused: false }
      ),
    }));
  };

  const handleTextAreaBlur = (id, coverType) => {
    setTextAreas((prevTextAreas) => ({
      ...prevTextAreas,
      [coverType]: prevTextAreas[coverType].map((textArea) =>
        textArea.id === id ? { ...textArea, focused: false } : textArea
      ),
    }));
  };

  const saveCoverAsImage = () => {
    const removeBorders = () => {
      const elements = coverRef.current.querySelectorAll(
        ".text-area, .left-board, .right-board, .bleed-area, .caution-area, .book-front-spine, .book-back-spine, .caution-area-right, .bleed-area-right, .book-cover, .book-back-cover"
      );
      elements.forEach((element) => {
        element.dataset.originalBorder = element.style.border;
        element.style.border = "none";
      });
      const uploadButtons = coverRef.current.querySelectorAll(".upload-button");
      uploadButtons.forEach((button) => {
        button.style.display = "none";
      });
    };

    const restoreBorders = () => {
      const elements = coverRef.current.querySelectorAll(
        ".text-area, .left-board, .right-board, .bleed-area, .caution-area, .book-front-spine, .book-back-spine, .caution-area-right, .bleed-area-right, .book-cover, .book-back-cover"
      );
      elements.forEach((element) => {
        element.style.border = element.dataset.originalBorder;
      });
      const uploadButtons = coverRef.current.querySelectorAll(".upload-button");
      uploadButtons.forEach((button) => {
        button.style.display = "block";
      });
    };
    const removeFrontCoverBorders = () => {
      const elements = frontcoverRef.current.querySelectorAll(
        ".text-area, .left-board, .bleed-area, .caution-area, .book-front-spine"
      );
      elements.forEach((element) => {
        element.dataset.originalBorder = element.style.border;
        element.style.border = "none";
      });
      const uploadButtons =
        frontcoverRef.current.querySelectorAll(".upload-button");
      uploadButtons.forEach((button) => {
        button.style.display = "none";
      });
    };

    const restoreFrontCoverBorders = () => {
      const elements = frontcoverRef.current.querySelectorAll(
        ".text-area, .left-board, .bleed-area, .caution-area, .book-front-spine"
      );
      elements.forEach((element) => {
        element.style.border = element.dataset.originalBorder;
      });
      const uploadButtons =
        frontcoverRef.current.querySelectorAll(".upload-button");
      uploadButtons.forEach((button) => {
        button.style.display = "block";
      });
    };
    const getCurrentTimeString = () => {
      const now = new Date();
      return now.toISOString().replace(/[-:.]/g, "");
    };

    const timeString = getCurrentTimeString();
    if (coverRef.current) {
      removeBorders();
      toPng(coverRef.current)
        .then((dataUrl) => {
          restoreBorders();

          const link = document.createElement("a");
          link.download = `book-cover-${timeString}.png`;
          link.href = dataUrl;
          link.click();

          const dataUrlToBlob = (dataUrl) => {
            const arr = dataUrl.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
          };

          const blob = dataUrlToBlob(dataUrl);
          const file = new File([blob], `book-cover-${timeString}.png`, {
            type: "image/png",
          });
          const token = localStorage.getItem("token");

          dispatch(uploadBookCover(bookId, file, token));
        })
        .catch((error) => {
          console.error("Error saving cover as image:", error);
          restoreBorders();
        });
    }
    if (frontcoverRef.current) {
      removeFrontCoverBorders();
      toPng(frontcoverRef.current)
        .then((dataUrl) => {
          restoreFrontCoverBorders();

          const link = document.createElement("a");
          link.download = `book-front-cover-${timeString}.png`;
          link.href = dataUrl;
          link.click();

          const dataUrlToBlob = (dataUrl) => {
            const arr = dataUrl.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
          };

          const blob = dataUrlToBlob(dataUrl);
          const file = new File([blob], `book-front-cover-${timeString}.png`, {
            type: "image/png",
          });
          const token = localStorage.getItem("token");
          dispatch(uploadFrontBookCover(bookId, file, token));
        })
        .catch((error) => {
          console.error("Error saving cover as image:", error);
          restoreBorders();
        });
    }
  };
  const zoomInFront = () => setFrontZoom((prevZoom) => prevZoom + 0.1);
  const zoomOutFront = () =>
    setFrontZoom((prevZoom) => Math.max(prevZoom - 0.1, 1.3));
  const zoomInBack = () => setBackZoom((prevZoom) => prevZoom + 0.1);
  const zoomOutBack = () =>
    setBackZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));

  return (
    <div className="book-editor-container">
      <Menu
        changeColor={changeColor}
        onSoftCoverDimensionChange={handleSoftCoverDimensionChange}
        onHardCoverDimensionChange={handleHardCoverDimensionChange}
        togglePreviewMode={togglePreviewMode}
        isPreviewMode={isPreviewMode}
        onAddTextArea={handleAddTextArea}
        saveCoverAsImage={saveCoverAsImage}
      />
      <div
        style={{
          width: "85%",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <div
          className="book-editor"
          style={{ transform: `scale(${zoomLevel / 100})` }}
        >
          <div className="zoom-controls">
            <ZoomButton onClick={zoomIn}>
              <ZoomInIcon fontSize="large" />
            </ZoomButton>
            <ZoomButton onClick={zoomOut}>
              <ZoomOutIcon fontSize="large" />
            </ZoomButton>
          </div>
          <div className="book-editor-cover" ref={coverRef}>
            {!isPreviewMode ? (
              currentCoverType === "softcover" ? (
                <Softcover
                  color={color}
                  coverHeight={coverHeight}
                  coverWidth={coverWidth}
                  renderTextAreas={renderTextAreas}
                  handleDragOver={handleDragOver}
                  handleCoverClick={handleCoverClick}
                  handleFrontCoverDrop={handleFrontCoverDrop}
                  handleBackCoverDrop={handleBackCoverDrop}
                  frontCoverImage={frontCoverImage}
                  setFrontCoverImage={setFrontCoverImage}
                  backCoverImage={backCoverImage}
                  setBackCoverImage={setBackCoverImage}
                  frontZoom={frontZoom}
                  backZoom={backZoom}
                  frontcoverRef={frontcoverRef}
                />
              ) : (
                <Hardcover
                  color={color}
                  coverHeight={coverHeight}
                  coverWidth={coverWidth}
                  renderTextAreas={renderTextAreas}
                  handleDragOver={handleDragOver}
                  handleCoverClick={handleCoverClick}
                  handleFrontCoverDrop={handleFrontCoverDrop}
                  handleBackCoverDrop={handleBackCoverDrop}
                  frontCoverImage={frontCoverImage}
                  setFrontCoverImage={setFrontCoverImage}
                  backCoverImage={backCoverImage}
                  setBackCoverImage={setBackCoverImage}
                  frontZoom={frontZoom}
                  backZoom={backZoom}
                  frontcoverRef={frontcoverRef}
                />
              )
            ) : (
              <div className="preview-mode">
                <div
                  className="preview-cover"
                  style={{
                    width: coverWidth,
                    height: coverHeight,
                    backgroundImage: frontCoverImage
                      ? `url(${frontCoverImage})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: color,
                  }}
                  title="Front Cover"
                >
                  {renderTextAreas("front")}
                </div>
                <div
                  className="preview-cover"
                  style={{
                    width: coverWidth,
                    height: coverHeight,
                    backgroundImage: backCoverImage
                      ? `url(${backCoverImage})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: color,
                  }}
                  title="Back Cover"
                >
                  {renderTextAreas("back")}
                </div>
              </div>
            )}
          </div>
          <div className="zoom-controls">
            <ZoomButton onClick={zoomIn}>
              <ZoomInIcon fontSize="large" />
            </ZoomButton>
            <ZoomButton onClick={zoomOut}>
              <ZoomOutIcon fontSize="large" />
            </ZoomButton>
          </div>
        </div>
      </div>
      {selection && (
        <Toolbar
          top={selection.top}
          left={selection.left}
          onBoldClick={() => applyStyleToSelection("bold")}
          onItalicClick={() => applyStyleToSelection("italic")}
          onUnderlineClick={() => applyStyleToSelection("underline")}
          onChangeFontSize={handleFontSizeChange}
          onChangeFontFamily={handleFontFamilyChange}
          onChangeColor={handleColorChange}
          ref={toolbarRef}
        />
      )}
    </div>
  );
};
export default BookEditor;
