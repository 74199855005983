import React, { useEffect, useState } from "react";
import { fetchBookList } from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import Books from "../components/Books";
import Sidebar from "../components/Sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import "./book.css";
function Book() {
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.reducer.loading);
  const err = useSelector((s) => s.reducer.error);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchBookList(token));
    }
  }, [dispatch]);

  const handleBookUpdate = () => {
    const token = localStorage.getItem("token");
    dispatch(fetchBookList(token));
  };

  return (
    <div
      className="container-fluid "
      // style={{ backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)" }}
    >
      <div className="row">
        {/* Sidebar column */}
        <div className={`col-md-2 ${!isActive ? "d-none" : ""} p-0`}>
          <Sidebar isActive={isActive} setIsActive={setIsActive} />
        </div>

        {/* Books content column */}
        <div className={`col-md-${isActive ? "9" : "12"} book-content`}>
          <Books onBookUpdate={handleBookUpdate} />
        </div>
      </div>
    </div>
  );
}

export default Book;
