import React, { useState } from "react";
import { FiLogOut, FiBook, FiUpload, FiSettings } from "react-icons/fi";
import { CiCalculator1 } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { getAuth, signOut } from "firebase/auth";
import "./sidebar.css";

function Sidebar({ isActive, setIsActive }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [headingState, setHeadingState] = useState(null);
  const [modalMode, setModalMode] = useState("");

  const openModal = (mode) => {
    setModalMode(mode);
    setModalOpen(true);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      <nav className={`nav ${isActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/quote">
              <span className="icon">
                <CiCalculator1 />
              </span>
              <span className="title"> Instant Quote</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                openModal("create");
                setHeadingState("Let's create a new journey");
              }}
            >
              <span className="icon">
                <FiBook />
              </span>
              <span className="title">Create Book</span>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                openModal("upload");
                setHeadingState("Upload a Book");
              }}
            >
              <span className="icon">
                <FiUpload />
              </span>
              <span className="title">Upload Book</span>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              <span className="icon">
                <FiSettings />
              </span>
              <span className="title">Settings</span>
            </Link>
          </li>
          <li>
            <Link onClick={handleSignOut}>
              <span className="icon">
                <FiLogOut />
              </span>
              <span className="title">Sign Out</span>
            </Link>
          </li>
        </ul>
      </nav>
      <Modal
        open={isModalOpen}
        setModalOpen={setModalOpen}
        heading={headingState}
        mode={modalMode}
      />
    </>
  );
}

export default Sidebar;
