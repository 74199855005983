import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CanvasContainer from "./CanvasContainer";

const UploadButton = styled("button")(({ theme }) => ({
  width: "200px",
  height: "200px",
  backgroundColor: "rgba(128, 128, 128, 0.3)", // Grey color with low opacity
  color: theme.palette.common.white,
  border: "none",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  cursor: "pointer",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "rgba(128, 128, 128, 0.7)", // Slightly darker on hover
  },
}));

export default function Hardcover({
  color = "#F5F5F5", // Default light grey background color
  coverHeight,
  coverWidth,
  frontZoom,
  backZoom,
}) {
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);

  // Separate states for front and back images
  const [frontCoverImage, setFrontCoverImage] = useState(null);
  const [backCoverImage, setBackCoverImage] = useState(null);

  const handleFileChange = (event, setImage) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);
      setImage(imageUrl);
    }
  };

  const handleDropEvent = (event, setImage) => {
    event.preventDefault();
    const imageFile = event.dataTransfer.files[0];
    if (imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);
      setImage(imageUrl);
    }
  };

  return (
    <>
      {/* Front Cover */}
      <div
        className="left-board"
        style={{
          backgroundColor: "#E3F2FD", // Light blue for front cover background
          border: "1px solid #64B5F6", // Blue border for front cover
        }}
        onDrop={(e) => handleDropEvent(e, setFrontCoverImage)}
        onDragOver={(e) => e.preventDefault()}
        title="Drag and drop an image to set the front cover"
      >
        <div className="bleed-area" style={{ backgroundColor: "#FFEBEE" }}>
          <div className="caution-area" style={{ backgroundColor: "#FFFDE7" }}>
            <div className="book-layout">
              <div
                className="book-cover"
                style={{
                  width: coverWidth,
                  height: coverHeight,
                  backgroundColor: "#FCE4EC",
                  backgroundImage: frontCoverImage
                    ? `url(${frontCoverImage})`
                    : "none",
                  backgroundSize:
                    frontZoom > 1.3
                      ? `${frontZoom * 100}% ${frontZoom * 100}%`
                      : "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                title="Front Cover"
              >
                <CanvasContainer width={coverWidth} height={coverHeight} />
                {!frontCoverImage && (
                  <UploadButton
                    className="upload-button"
                    onClick={() => frontInputRef.current.click()}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <span>Upload or Drop the Image here</span>
                  </UploadButton>
                )}
                <input
                  type="file"
                  ref={frontInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setFrontCoverImage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="spine"
        style={{
          width: "30px",
          height: coverHeight,
          backgroundColor: "#DCE775", // Light Green for Spine
          border: "1px solid #9CCC65", // Darker Green Border
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ writingMode: "vertical-rl", textAlign: "center" }}>
          Spine Text (Optional)
        </span>
      </div>

      {/* Back Cover */}
      <div
        className="right-board"
        style={{
          backgroundColor: "#E8F5E9", // Light green for back cover background
          border: "1px solid #66BB6A", // Green border for back cover
        }}
        onDrop={(e) => handleDropEvent(e, setBackCoverImage)}
        onDragOver={(e) => e.preventDefault()}
        title="Drag and drop an image to set the back cover"
      >
        <div
          className="bleed-area-right"
          style={{ backgroundColor: "#FFEBEE" }}
        >
          <div
            className="caution-area-right"
            style={{ backgroundColor: "#FFFDE7" }}
          >
            <div className="book-layout">
              <div
                className="book-back-cover"
                style={{
                  width: coverWidth,
                  height: coverHeight,
                  backgroundColor: "#E1F5FE",
                  backgroundImage: backCoverImage
                    ? `url(${backCoverImage})`
                    : "none",
                  backgroundSize:
                    backZoom > 1.3
                      ? `${backZoom * 100}% ${backZoom * 100}%`
                      : "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                title="Back Cover"
              >
                <CanvasContainer width={coverWidth} height={coverHeight} />
                {!backCoverImage && (
                  <UploadButton
                    className="upload-button"
                    onClick={() => backInputRef.current.click()}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <span>Upload or Drop the Image here</span>
                  </UploadButton>
                )}
                <input
                  type="file"
                  ref={backInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, setBackCoverImage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
