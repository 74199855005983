import React, { useState, useEffect } from "react";
import { ReactReader } from "react-reader";
import axios from "axios";
import { useParams } from "react-router-dom";

function EpubViewer() {
  const [location, setLocation] = useState(0);
  const [response, setResponse] = useState(0);

  const { id } = useParams();
  const token = localStorage.getItem("token");
  // const URL = "http://localhost:3001/api";
  const URL = "https://app.simplymemoirs.com/api";

  const configure = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios
          .get(`${URL}/epubbook/${id}`, configure)
          .then((res) => {
            console.log(res.data, "response");
            setResponse(res.data);
          });
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ height: "80vh" }}>
      {response ? (
        <ReactReader
          title="Simply Memoirs"
          url={response?.data?.Location}
          location={location}
          locationChanged={(epubcfi) => setLocation(epubcfi)}
          swipeable={true}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          Loading
        </div>
      )}
    </div>
  );
}

export default EpubViewer;
