// import React, { useState,useId } from "react";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import "../../App.css";
// import "./MobilePreview.css";
// import "react-device-frameset/styles/marvel-devices.min.css";
// import Dropdown from "react-bootstrap/Dropdown";
// import { useDispatch,useSelector } from "react-redux";
// import AmzKindle from "./kindleDevice/AmzKindle";
// import Ipad from "./IPad/Ipad";
// import Iphone from "./Iphone14/Iphone";
// import IpadPro from "./IPadPro/IpadPro";
// import PrintPreview from "./PrintPreview"; // Import PrintPreview component here
// import { setSelectedPageSize } from "../../redux/action/action";

// function MobilePreview({ text, title }) {
//   const dispatch = useDispatch();
//   const [printPreviewKey, setPrintPreviewKey] = useState(0);
//   const selectedPageSize = useSelector((state) => state.pageSize.selectedPageSize);
//   const selectedTemplate = useSelector((state) => state.template.selectedTemplate);

//   let arr = [
//     "A4",
//     "8.5 x 11",
//     "8 x 10",
//     "6 x 9",
//     "11 x 8.5",
//     "10 x 8",
//     "9 x 6",
//   ];
//   const [devices, selectDevices] = useState("8.5 x 11");

//   function handelClick(name) {
//     selectDevices(name);
//     dispatch(setSelectedPageSize(name));
//     setPrintPreviewKey((prevKey) => prevKey + 1);
//   }

//   const idx = useId();

//   const getFontStyles = () => {
//     switch (selectedTemplate) {
//       case "Template 1":
//         return { fontFamily: "Poppins", fontSize: "15.5px" };
//       case "Template 2":
//         return { fontFamily: "Georgia, serif", fontSize: "18px" };
//       case "Template 3":
//         return { fontFamily: "Courier New, monospace", fontSize: "14px" };
//       case "Template 4":
//         return { fontFamily: "Times New Roman, serif", fontSize: "20px" };
//       default:
//         return {};
//     }
//   };

//   return (
//     <>
//       <DropdownButton id='dropdown-item-button' title={devices ? devices : "Devices"} style={{ background: "black", border: "none", borderRadius: "6px" }} variant='dark'>
//         {arr.map((name, id) => (
//           <>
//             {name === "A4" && (
//               <Dropdown.Item key='Print-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
//                 Print
//               </Dropdown.Item>
//             )}
//             {name === "8.5 x 11" && (
//               <Dropdown.Item key='Portrait-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
//                 Portrait
//               </Dropdown.Item>
//             )}
//             {name === "11 x 8.5" && (
//               <Dropdown.Item key='Landscape-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
//                 Landscape
//               </Dropdown.Item>
//             )}
//             {name === "Paper White" && (
//               <Dropdown.Item key='kindle-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
//                 Kindle
//               </Dropdown.Item>
//             )}
//             {name === "iPhone 14 Pro" && (
//               <Dropdown.Item key='apple-divider' as='button' disabled style={{ color: "gray", cursor: "default", fontSize: "13px", paddingLeft: "8px" }}>
//                 Apple
//               </Dropdown.Item>
//             )}
//             <Dropdown.Item as='button' key={idx} onClick={() => handelClick(name)} variant='dark' style={{ marginTop: "0px" }}>
//               {name}
//             </Dropdown.Item>
//           </>
//         ))}
//       </DropdownButton>
//       {devices === "iPhone 14 Pro" ? (
//         <Iphone font={getFontStyles} data={text} />
//       ) : devices === "iPad" ? (
//         <Ipad font={getFontStyles} data={text} />
//       ) : devices === "Paper White" ? (
//         <AmzKindle font={getFontStyles} data={text} />
//       ) : devices === "iPad Pro" ? (
//         <IpadPro font={getFontStyles} data={text} />
//       ) : (
//         <PrintPreview text={text} key={printPreviewKey} selectedPageSize={selectedPageSize}showButton={true} showRadio={false} />
//       )}
//     </>
//   );
// }

// export default MobilePreview;




import React, { useState, useEffect, useId } from "react";
import { useParams } from "react-router-dom"; // Import useParams to access route parameters
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import AmzKindle from "./kindleDevice/AmzKindle";
import Ipad from "./IPad/Ipad";
import Iphone from "./Iphone14/Iphone";
import IpadPro from "./IPadPro/IpadPro";
import PrintPreview from "./PrintPreview";
import { setSelectedPageSize } from "../../redux/action/action";
import axiosInstance from "../../utils/axiosInstance";

function MobilePreview({ text, title }) {
  const dispatch = useDispatch();
  const { id: bookId } = useParams(); // Extract bookId from the URL
  const [printPreviewKey, setPrintPreviewKey] = useState(0);
  const selectedPageSize = useSelector((state) => state.pageSize.selectedPageSize);
  const selectedTemplate = useSelector((state) => state.template.selectedTemplate);
  const [devices, setDevices] = useState("8.5 x 11"); // Default value
  const token = localStorage.getItem("token"); // Get token from local storage
  console.log(bookId, 'bookId')
  const arr = [
    "A4",
    "8.5 x 11",
    "8 x 10",
    "6 x 9",
    "11 x 8.5",
    "10 x 8",
    "9 x 6",
  ];

  const idx = useId();

  // Function to fetch the user's selected preview on component mount
  // Function to fetch the selected preview
  const fetchSelectedPreview = async () => {
    try {
      const response = await axiosInstance.get(`/get-preview/${bookId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include bearer token in headers
        },
      });
      console.log(response, 'response')
      if (response.status === 200 && response.data.selectedPreview) {
        setDevices(response.data.selectedPreview);
      } else {
        console.error("Failed to fetch preview");
      }
    } catch (error) {
      console.error("Error fetching preview:", error);
    }
  };

  // Function to save the user's selected preview
  const saveSelectedPreview = async (selectedPreview) => {
    try {
      const response = await axiosInstance.post(
        "/save-preview",
        {
          bookId: bookId, // Use bookId from URL
          selectedPreview,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include bearer token in headers
          },
        }
      );

      if (response.status !== 200) {
        console.error("Failed to save preview");
      }
    } catch (error) {
      console.error("Error saving preview:", error);
    }
  };


  // Function to handle device selection
  const handelClick = (name) => {
    setDevices(name);
    dispatch(setSelectedPageSize(name));
    setPrintPreviewKey((prevKey) => prevKey + 1);
    saveSelectedPreview(name); // Save selected preview to the backend
  };

  const getFontStyles = () => {
    switch (selectedTemplate) {
      case "Template 1":
        return { fontFamily: "Poppins", fontSize: "15.5px" };
      case "Template 2":
        return { fontFamily: "Georgia, serif", fontSize: "18px" };
      case "Template 3":
        return { fontFamily: "Courier New, monospace", fontSize: "14px" };
      case "Template 4":
        return { fontFamily: "Times New Roman, serif", fontSize: "20px" };
      default:
        return {};
    }
  };

  // Fetch selected preview when the component mounts
  useEffect(() => {
    fetchSelectedPreview();
  }, [bookId]);

  return (
    <>
      <DropdownButton
        id="dropdown-item-button"
        title={devices ? devices : "Devices"}
        style={{ background: "black", border: "none", borderRadius: "6px" }}
        variant="dark"
      >
        {arr.map((name, id) => (
          <React.Fragment key={id}>
            {name === "A4" && (
              <Dropdown.Item
                key="Print-divider"
                as="button"
                disabled
                style={{
                  color: "gray",
                  cursor: "default",
                  fontSize: "13px",
                  paddingLeft: "8px",
                }}
              >
                Print
              </Dropdown.Item>
            )}
            {name === "8.5 x 11" && (
              <Dropdown.Item
                key="Portrait-divider"
                as="button"
                disabled
                style={{
                  color: "gray",
                  cursor: "default",
                  fontSize: "13px",
                  paddingLeft: "8px",
                }}
              >
                Portrait
              </Dropdown.Item>
            )}
            {name === "11 x 8.5" && (
              <Dropdown.Item
                key="Landscape-divider"
                as="button"
                disabled
                style={{
                  color: "gray",
                  cursor: "default",
                  fontSize: "13px",
                  paddingLeft: "8px",
                }}
              >
                Landscape
              </Dropdown.Item>
            )}
            <Dropdown.Item
              as="button"
              key={idx}
              onClick={() => handelClick(name)}
              variant="dark"
              style={{ marginTop: "0px" }}
            >
              {name}
            </Dropdown.Item>
          </React.Fragment>
        ))}
      </DropdownButton>
      {devices === "iPhone 14 Pro" ? (
        <Iphone font={getFontStyles} data={text} />
      ) : devices === "iPad" ? (
        <Ipad font={getFontStyles} data={text} />
      ) : devices === "Paper White" ? (
        <AmzKindle font={getFontStyles} data={text} />
      ) : devices === "iPad Pro" ? (
        <IpadPro font={getFontStyles} data={text} />
      ) : (
        <PrintPreview
          text={text}
          key={printPreviewKey}
          selectedPageSize={selectedPageSize}
          showButton={true}
          showRadio={false}
        />
      )}
    </>
  );
}

export default MobilePreview;
