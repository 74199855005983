import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  createContext,
} from "react";
import CanvasComponent from "./CanvasComponent";
import ToolbarCanvas from "./ToolbarCanvas";
import { CanvasContext } from "./CanvasContext";

const getInitialData = (data = [], type = "TEXT") => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${data.length}`,
    position: {
      top: 100,
      left: 100,
    },
    dimension: {
      width: "150",
      height: type === "TEXT" ? "50" : "150",
    },
    content: type === "TEXT" ? "Sample Text" : "",
  };
};

const CanvasContainer = ({ width, height }) => {
  const [canvasData, setCanvasData] = useState([]);
  const [activeSelection, setActiveSelection] = useState(new Set());
  const [enableQuillToolbar, setEnableQuillToolbar] = useState(false);

  const containerRef = useRef(null);
  const isSelectAll = useRef(false);

  const updateCanvasData = (data) => {
    const currentDataIndex =
      canvasData.findIndex((canvas) => canvas.id === data.id) ?? -1;
    const updatedData = { ...canvasData[currentDataIndex], ...data };
    canvasData.splice(currentDataIndex, 1, updatedData);
    setCanvasData([...canvasData]);
  };

  const addElement = (type) => {
    const defaultData = getInitialData(canvasData, type);
    setCanvasData([...canvasData, { ...defaultData, type: type ?? "TEXT" }]);
    activeSelection?.clear();
    activeSelection?.add(defaultData.id);
    setActiveSelection(new Set(activeSelection));
  };

  const deleteElement = useCallback(() => {
    setCanvasData([
      ...canvasData.filter((data) => {
        if (data.id && activeSelection?.has(data.id)) {
          activeSelection?.delete(data.id);
          return false;
        }
        return true;
      }),
    ]);
    setActiveSelection(new Set(activeSelection));
  }, [activeSelection, canvasData]);

  const selectAllElement = useCallback(() => {
    isSelectAll.current = true;
    canvasData.forEach((data) => activeSelection.add(data.id || ""));
    setActiveSelection(new Set(activeSelection));
  }, [activeSelection, canvasData]);

  const context = {
    actions: {
      setCanvasData,
      setActiveSelection,
      updateCanvasData,
      addElement,
      setEnableQuillToolbar,
    },
    state: {
      canvasData,
      activeSelection,
      enableQuillToolbar,
    },
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Delete") {
        deleteElement();
      } else if (["a", "A"].includes(event.key) && event.ctrlKey) {
        event.preventDefault();
        selectAllElement();
      }
    },
    [deleteElement, selectAllElement]
  );

  const outSideClickHandler = () => {
    isSelectAll.current = false;
    setActiveSelection(new Set());
  };

  const handleMouseDown = useCallback(() => {
    if (!isSelectAll.current) {
      return;
    }

    outSideClickHandler();
    isSelectAll.current = false;
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleKeyDown, handleMouseDown]);
  return (
    <div ref={containerRef} style={{ width, height }}>
      <CanvasContext.Provider value={context}>
        <ToolbarCanvas isEditEnable={enableQuillToolbar} />
        <div className="canvas-container">
          {canvasData?.map((canvas) => {
            return <CanvasComponent {...canvas} />;
          })}
        </div>
        {/* {JSON.stringify(canvasData)} */}
      </CanvasContext.Provider>
    </div>
  );
};

export default CanvasContainer;
