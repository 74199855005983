import React, { useState, useEffect, useRef } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

const langs = [
  ["Afrikaans", ["af-ZA"]],
  ["Bahasa Indonesia", ["id-ID"]],
  ["Bahasa Melayu", ["ms-MY"]],
  ["Deutsch", ["de-DE"]],
  ["English", ["en-US", "United States"], ["en-GB", "United Kingdom"]],
  ["Español", ["es-ES", "España"], ["es-MX", "México"]],
  ["Français", ["fr-FR"]],
  ["Italiano", ["it-IT"]],
  ["日本語", ["ja-JP"]],
  ["한국어", ["ko-KR"]],
  ["中文", ["cmn-Hans-CN", "普通话 (中国大陆)"]],
  ["Pусский", ["ru-RU"]],
  ["اردو", ["ur-PK", "پاکستان"]],
];

const Dictaphone = ({ setValue }) => {
  const [isListening, setIsListening] = useState(false);
  const [finalTranscript, setFinalTranscript] = useState("");
  const [selectedLang, setSelectedLang] = useState("en-US");
  const recognitionRef = useRef(null);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert("Your browser does not support speech recognition.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = selectedLang;
    recognitionRef.current = recognition;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      let interimTranscript = "";
      let finalTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      const formattedTranscript = finalTranscript.replace(/\n/g, " ").trim();
      setValue((prevValue) => {
        const isLastParagraphOpen = prevValue.endsWith("</p>");
        if (isLastParagraphOpen) {
          return prevValue.replace(/<\/p>$/, ` ${formattedTranscript}</p>`);
        } else {
          return `${prevValue}<p>${formattedTranscript}</p>`;
        }
      });

      setFinalTranscript(finalTranscript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error detected: ", event.error);
    };

    return () => {
      recognition.stop();
    };
  }, [setValue, selectedLang]);

  const handleToggleListening = () => {
    if (isListening) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.lang = selectedLang;
      recognitionRef.current.start();
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLang(event.target.value);
    if (isListening) {
      recognitionRef.current.stop();
      recognitionRef.current.lang = event.target.value;
      recognitionRef.current.start();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className="d-flex align-items-center me-3"
        style={{ width: "max-content" }}
      >
        <select
          id="language-select"
          className="form-select"
          value={selectedLang}
          onChange={handleLanguageChange}
          style={{ width: "auto" }}
        >
          {langs.map((lang, index) => (
            <optgroup key={index} label={lang[0]}>
              {lang.slice(1).map((dialect, dialectIndex) => (
                <option key={dialectIndex} value={dialect[0]}>
                  {dialect.length > 1 ? dialect[1] : lang[0]}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
      </div>

      <button
        className="btn btn-light"
        onClick={handleToggleListening}
        style={{ padding: "5px" }}
      >
        {isListening ? (
          <FaMicrophoneSlash style={{ fontSize: "32px", color: "#503981" }} />
        ) : (
          <FaMicrophone style={{ fontSize: "32px", color: "#503981" }} />
        )}
      </button>
    </div>
  );
};

export default Dictaphone;
