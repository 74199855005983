// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios"; // Import Axios
// import { getAuth, signOut } from "firebase/auth";
// import "./Navbar.css";

// function Navbar() {
//   const [userRole, setUserRole] = useState(null); // State to store user role
//   const navigate = useNavigate();
//   const URL = "https://app.simplymemoirs.com";
//   // const URL ="http://localhost:3001";

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     axios
//       .get(`${URL}/api/singleuser`, {
//         headers: { Authorization: `Bearer ${token}` },
//       })
//       .then((response) => {
//         setUserRole(response.data.userRole);
//       })
//       .catch((error) => {
//         console.error("Error fetching user role:", error);
//       });
//   }, []);

//   const handleSignOut = () => {
//     localStorage.clear();
//     const auth = getAuth();
//     signOut(auth)
//       .then(() => {
//         navigate("/signin");
//       })
//       .catch((error) => {
//         console.error("Error signing out:", error);
//       });
//   };

//   return (
//     <>
//       <div className="nav-wrapper">
//         <div className="logo-container">
//           <img
//             className="logo"
//             src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/simply%20logo.png"
//             alt="Logo"
//             onClick={() => {
//               navigate("/books");
//             }}
//           />
//         </div>
//         <nav>
//           <input className="hidden" type="checkbox" id="menuToggle" />
//           <label className="menu-btn" htmlFor="menuToggle">
//             <div className="menu" />
//             <div className="menu" />
//             <div className="menu" />
//           </label>
//           <div className="nav-container">
//             <ul className="nav-tabs">
//               <li
//                 className="nav-tab"
//                 onClick={() => {
//                   navigate("/books");
//                 }}
//               >
//                 Home
//               </li>
//               {userRole !== 0 && userRole !== 2 && (
//                 <li
//                   className="nav-tab"
//                   onClick={() => {
//                     navigate("/admin");
//                   }}
//                 >
//                   Admin
//                 </li>
//               )}
//               {userRole !== 0 && userRole !== 1 && (
//                 <li
//                   className="nav-tab"
//                   onClick={() => {
//                     navigate("/superadmin");
//                   }}
//                 >
//                   Super Admin
//                 </li>
//               )}
//               <li
//                 className="nav-tab"
//                 onClick={() => {
//                   navigate("/orderlist");
//                 }}
//               >
//                 Orders
//               </li>
//               <li
//                 className="nav-tab"
//                 onClick={() => {
//                   handleSignOut();
//                 }}
//               >
//                 Sign Out
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </div>
//     </>
//   );
// }

// export default Navbar;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios
import { getAuth, signOut } from "firebase/auth";
import "./Navbar.css";
import axiosInstance from "../../utils/axiosInstance";

function Navbar() {
  const [userRole, setUserRole] = useState(null); // State to store user role
  const navigate = useNavigate();
  const URL = "https://app.simplymemoirs.com";
  // const URL = "http://localhost:3001";

  const handleSignOut = () => {
    localStorage.clear();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/signin");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      <div className="nav-wrapper">
        <div className="logo-container">
          <img
            className="logo"
            src="https://simplymemoirs.s3.us-west-1.amazonaws.com/images/simply%20logo.png"
            alt="Logo"
            onClick={() => {
              navigate("/books");
            }}
          />
        </div>
        <nav>
          <input className="hidden" type="checkbox" id="menuToggle" />
          <label className="menu-btn" htmlFor="menuToggle">
            <div className="menu" />
            <div className="menu" />
            <div className="menu" />
          </label>
          <div className="nav-container">
            <ul className="nav-tabs">
              <li
                className="nav-tab"
                onClick={() => {
                  navigate("/books");
                }}
              >
                Home
              </li>
              {userRole !== 0 && userRole !== 2 && (
                <li
                  className="nav-tab"
                  onClick={() => {
                    navigate("/admin");
                  }}
                >
                  Admin
                </li>
              )}
              {userRole !== 0 && userRole !== 1 && (
                <li
                  className="nav-tab"
                  onClick={() => {
                    navigate("/superadmin");
                  }}
                >
                  Super Admin
                </li>
              )}
              <li
                className="nav-tab"
                onClick={() => {
                  navigate("/orderlist");
                }}
              >
                Orders
              </li>
              <li
                className="nav-tab"
                onClick={() => {
                  handleSignOut();
                }}
              >
                Sign Out
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
