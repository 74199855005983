// import React, { useState, useEffect } from "react";
// import Signinimg from "../images/bookssign.png";
// import Typography from "@mui/material/Typography";
// import TextField from "@mui/material/TextField";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import LockIcon from "@mui/icons-material/Lock";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import FormControl from "@mui/material/FormControl";
// import { ToastContainer, toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { signUp, clearState } from "../redux/action/action";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// // Styled TextField with Black and Gray color scheme
// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#6C757D", // Gray for focused labels
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#6C757D",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#6C757D", // Gray border color for inputs
//       borderRadius: "10px",
//     },
//     "&:hover fieldset": {
//       borderColor: "#6C757D", // Gray on hover
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#6C757D", // Gray when focused
//     },
//     "& input": {
//       height: "0.8rem",
//       fontFamily: "Poppins",
//       fontSize: "15px",
//       color: "#000000", // Black text input
//     },
//   },
//   "& .MuiFormHelperText-root": {
//     fontSize: "0.7rem",
//     color: "#6C757D", // Gray for helper text
//   },
// });

// // Styled Button with Black and Gray color scheme
// const ColorButton = styled(Button)(() => ({
//   width: "22%", // Button width adjusted
//   padding: "10px 20px",
//   fontSize: "1rem",
//   fontFamily: "Poppins",
//   backgroundColor: "#000000", // Black button background
//   color: "white",
//   "&:hover": {
//     backgroundColor: "#333333", // Slightly lighter black on hover
//     boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//   },
//   transition: "all 0.3s ease-in-out",
// }));

// // Custom layout styles
// const customStyles = {
//   row: {
//     height: "100vh",
//     "--bs-gutter-x": "0",
//   },
//   col: {
//     height: "100%",
//   },
//   contentWrapper: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100%",
//     padding: "2rem",
//     backgroundColor: "#F8F9FA", // Light gray background
//   },
// };

// const auth = getAuth();

// export default function Signup() {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     firstName: "",
//     lastName: "",
//     confirmPassword: "",
//   });
//   const [showPass, setShowPass] = useState(false);
//   const [showConfirmPass, setShowConfirmPass] = useState(false);
//   const [users, setUsers] = useState(null);
//   const dispatch = useDispatch();
//   const loading = useSelector((state) => state?.signupreducer?.loading);
//   const user = useSelector((state) => state?.signupreducer?.user);
//   const error = useSelector((state) => state?.signupreducer?.error);
//   const navigate = useNavigate();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const isEmailValid = (email) => {
//     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
//     return emailRegex.test(email);
//   };

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
//       if (userAuth) {
//         setUsers(userAuth);
//       } else {
//         setUsers(null);
//       }
//     });
//     return unsubscribe;
//   }, []);

//   useEffect(() => {
//     const token = users?.accessToken;
//     if (token) {
//       dispatch(clearState());
//     } else if (error) {
//       toast.error(error);
//       dispatch(clearState());
//     }
//   }, [user, error]);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const newEmail = formData.email;

//     if (!isEmailValid(newEmail)) {
//       toast.error("Invalid Email");
//       return;
//     }
//     if (formData.password.length < 6) {
//       toast.error("Password must be at least 6 characters long.");
//       return;
//     }
//     if (formData.password !== formData.confirmPassword) {
//       toast.error("Passwords do not match.");
//       return;
//     }
//     if (!formData.firstName) {
//       toast.error("Please provide your first name.");
//       return;
//     }
//     if (!formData.lastName) {
//       toast.error("Please provide your last name.");
//       return;
//     }
//     dispatch(signUp(formData));
//     navigate("/signin");
//     setFormData({
//       email: "",
//       password: "",
//       firstName: "",
//       lastName: "",
//       confirmPassword: "",
//     });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <FormControl className="col-12">
//         <div className="row" style={customStyles.row}>
//           <div className="col-6" style={customStyles.col}>
//             <img src={Signinimg} width="100%" height="100%" alt="Loading" />
//           </div>
//           <div className="col-6" style={customStyles.col}>
//             <div style={customStyles.contentWrapper}>
//               <Typography
//                 variant="h3"
//                 sx={{
//                   color: "#000000", // Black for title
//                   fontWeight: "600",
//                   fontFamily: "Poppins",
//                   fontSize: "4.5vw",
//                   textAlign: "center",
//                   lineHeight: "1.2",
//                 }}
//               >
//                 Welcome
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   color: "#6C757D", // Gray for subtitle
//                   fontWeight: "400",
//                   fontFamily: "Poppins",
//                   marginBottom: "2rem",
//                   fontSize: "1.2vw",
//                   textAlign: "center",
//                 }}
//               >
//                 Register your account
//               </Typography>
//               <CssTextField
//                 label="First Name"
//                 name="firstName"
//                 variant="outlined"
//                 sx={{ marginBottom: "1.5rem", width: "75%" }}
//                 placeholder="First Name"
//                 onChange={handleInputChange}
//               />
//               <CssTextField
//                 label="Last Name"
//                 name="lastName"
//                 variant="outlined"
//                 sx={{ marginBottom: "1.5rem", width: "75%" }}
//                 placeholder="Last Name"
//                 onChange={handleInputChange}
//               />
//               <CssTextField
//                 label="Email"
//                 name="email"
//                 value={formData.email}
//                 variant="outlined"
//                 sx={{ marginBottom: "1.5rem", width: "75%" }}
//                 placeholder="Email"
//                 InputProps={{
//                   startAdornment: (
//                     <MailOutlineIcon
//                       sx={{ marginRight: 1, color: "#6C757D" }}
//                     />
//                   ),
//                 }}
//                 onChange={handleInputChange}
//               />
//               <CssTextField
//                 label="Password"
//                 name="password"
//                 variant="outlined"
//                 sx={{ marginBottom: "1.5rem", width: "75%" }}
//                 InputProps={{
//                   startAdornment: (
//                     <LockIcon sx={{ marginRight: 1, color: "#6C757D" }} />
//                   ),
//                   endAdornment: showPass ? (
//                     <VisibilityIcon
//                       onClick={() => setShowPass(!showPass)}
//                       sx={{ cursor: "pointer", color: "#6C757D" }}
//                     />
//                   ) : (
//                     <VisibilityOffIcon
//                       onClick={() => setShowPass(!showPass)}
//                       sx={{ cursor: "pointer", color: "#6C757D" }}
//                     />
//                   ),
//                 }}
//                 placeholder="Password"
//                 type={showPass ? "text" : "password"}
//                 value={formData.password}
//                 onChange={handleInputChange}
//                 helperText={
//                   "Password must be more than 6 characters with uppercase, lowercase and contain special characters."
//                 }
//               />
//               <CssTextField
//                 label="Confirm password"
//                 name="confirmPassword"
//                 variant="outlined"
//                 sx={{ marginBottom: "2rem", width: "75%" }}
//                 InputProps={{
//                   startAdornment: (
//                     <LockIcon sx={{ marginRight: 1, color: "#6C757D" }} />
//                   ),
//                   endAdornment: showConfirmPass ? (
//                     <VisibilityIcon
//                       onClick={() => setShowConfirmPass(!showConfirmPass)}
//                       sx={{ cursor: "pointer", color: "#6C757D" }}
//                     />
//                   ) : (
//                     <VisibilityOffIcon
//                       onClick={() => setShowConfirmPass(!showConfirmPass)}
//                       sx={{ cursor: "pointer", color: "#6C757D" }}
//                     />
//                   ),
//                 }}
//                 placeholder="Confirm password"
//                 type={showConfirmPass ? "text" : "password"}
//                 value={formData.confirmPassword}
//                 onChange={handleInputChange}
//               />

//               <Link
//                 className="mt-2 d-flex justify-content-end w-75"
//                 style={{ textDecoration: "none" }}
//                 to={"/signin"}
//               >
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     color: "#6C757D", // Gray link
//                     fontSize: "1vw",
//                     fontFamily: "Poppins",
//                   }}
//                 >
//                   Already have an account?
//                 </Typography>
//               </Link>

//               <ColorButton
//                 variant="contained"
//                 sx={{ marginTop: 3 }}
//                 type="submit"
//                 disabled={loading}
//                 onClick={handleSubmit}
//               >
//                 Register{" "}
//               </ColorButton>
//             </div>
//           </div>
//         </div>
//       </FormControl>
//       <ToastContainer />
//     </form>
//   );
// }

import React, { useState, useEffect } from "react";
import Signinimg from "../images/bookssign.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { signUp, clearState } from "../redux/action/action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Styled TextField with white color scheme
const CssTextField = styled(TextField)({
  "& label": {
    color: "#FFFFFF", // White for label
  },
  "& label.Mui-focused": {
    color: "#FFFFFF", // White for focused label
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF", // White border
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF", // White on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF", // White when focused
    },
    "& input": {
      height: "0.8rem",
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#FFFFFF", // White input text
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.75rem",
    color: "#FFFFFF", // White helper text
  },
});

// Styled Button with purple gradient and white text
const ColorButton = styled(Button)(() => ({
  width: "75%",
  padding: "10px 20px",
  fontSize: "1rem",
  fontFamily: "Poppins",
  backgroundColor: "#1f1f1f", // Purple button background
  color: "white",
  "&:hover": {
    backgroundColor: "#1f1f1f",
    boxShadow: "0px 4px 12px rgba(142, 45, 226, 0.1)",
  },
  transition: "all 0.3s ease-in-out",
}));

// Custom layout styles (same as signin)
const customStyles = {
  row: {
    // height: "100vh",
    "--bs-gutter-x": "0",
    // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
    backgroundColor: "#404040",
  },
  col: {
    height: "100%",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "2rem",
    color: "white",
  },
};

const auth = getAuth();

export default function Signup() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.signupreducer?.loading);
  const user = useSelector((state) => state?.signupreducer?.user);
  const error = useSelector((state) => state?.signupreducer?.error);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        setUsers(userAuth);
      } else {
        setUsers(null);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const token = users?.accessToken;
    if (token) {
      dispatch(clearState());
    } else if (error) {
      toast.error(error);
      dispatch(clearState());
    }
  }, [user, error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newEmail = formData.email;

    if (!isEmailValid(newEmail)) {
      toast.error("Invalid Email");
      return;
    }
    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!formData.firstName) {
      toast.error("Please provide your first name.");
      return;
    }
    if (!formData.lastName) {
      toast.error("Please provide your last name.");
      return;
    }
    dispatch(signUp(formData));
    navigate("/signin");
    setFormData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl className="col-12">
        <div className="row" style={customStyles.row}>
          <div className="col-6" style={customStyles.col}>
            <img src={Signinimg} width="100%" height="100%" alt="Loading" />
          </div>
          <div className="col-6" style={customStyles.col}>
            <div style={customStyles.contentWrapper}>
              <Typography
                variant="h3"
                sx={{
                  color: "#FFFFFF", // White for title
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: "3vw",
                  textAlign: "center",
                  lineHeight: "1.2",
                }}
              >
                Welcome
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#FFFFFF", // White for subtitle
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  marginBottom: "2rem",
                  fontSize: "1.1vw",
                  textAlign: "center",
                }}
              >
                Register your account
              </Typography>
              <CssTextField
                label="First Name"
                name="firstName"
                variant="outlined"
                sx={{ marginBottom: "1.5rem", width: "75%" }}
                placeholder="First Name"
                onChange={handleInputChange}
              />
              <CssTextField
                label="Last Name"
                name="lastName"
                variant="outlined"
                sx={{ marginBottom: "1.5rem", width: "75%" }}
                placeholder="Last Name"
                onChange={handleInputChange}
              />
              <CssTextField
                label="Email"
                name="email"
                value={formData.email}
                variant="outlined"
                sx={{ marginBottom: "1.5rem", width: "75%" }}
                placeholder="Email"
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon
                      sx={{ marginRight: 1, color: "#FFFFFF" }}
                    />
                  ),
                }}
                onChange={handleInputChange}
              />
              <CssTextField
                label="Password"
                name="password"
                variant="outlined"
                sx={{ marginBottom: "1.5rem", width: "75%" }}
                InputProps={{
                  startAdornment: (
                    <LockIcon sx={{ marginRight: 1, color: "#FFFFFF" }} />
                  ),
                  endAdornment: showPass ? (
                    <VisibilityIcon
                      onClick={() => setShowPass(!showPass)}
                      sx={{ cursor: "pointer", color: "#FFFFFF" }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowPass(!showPass)}
                      sx={{ cursor: "pointer", color: "#FFFFFF" }}
                    />
                  ),
                }}
                placeholder="Password"
                type={showPass ? "text" : "password"}
                value={formData.password}
                onChange={handleInputChange}
                helperText={
                  "Password must be more than 6 characters with uppercase, lowercase and contain special characters."
                }
              />
              <CssTextField
                label="Confirm password"
                name="confirmPassword"
                variant="outlined"
                sx={{ marginBottom: "2rem", width: "75%" }}
                InputProps={{
                  startAdornment: (
                    <LockIcon sx={{ marginRight: 1, color: "#FFFFFF" }} />
                  ),
                  endAdornment: showConfirmPass ? (
                    <VisibilityIcon
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                      sx={{ cursor: "pointer", color: "#FFFFFF" }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                      sx={{ cursor: "pointer", color: "#FFFFFF" }}
                    />
                  ),
                }}
                placeholder="Confirm password"
                type={showConfirmPass ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />

              <Link
                className="mt-2 d-flex justify-content-end w-75"
                style={{ textDecoration: "none" }}
                to={"/signin"}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#FFFFFF", // White link
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                  }}
                >
                  Already have an account?
                </Typography>
              </Link>

              <ColorButton
                variant="contained"
                sx={{ marginTop: 3 }}
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
              >
                Register{" "}
              </ColorButton>
            </div>
          </div>
        </div>
      </FormControl>
      <ToastContainer />
    </form>
  );
}
