import "../App.css";
import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import axios from "axios";
import OrdersTable from "./Orderstable";
import InvoicesTable from "./InvoicesTable";
import ShipmentTable from "./ShipmentTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f1f1f",
    color: "#ffffff",
    padding: "20px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    color: "#1f1f1f",
    padding: "15px",
  },

  "&.MuiTableRow-hover:hover &": {
    backgroundColor: "#1f1f1f",
    color: "#ffffff",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
  // "&:hover": {
  //   backgroundColor: "#1f1f1f",
  //   color: "#ffff",
  //   // Dark background on hover
  //   "& td": {
  //     color: "#ffffff", // White text on hover for all cells
  //   },
  // },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showOrdersContent, setShowOrdersContent] = useState(false);
  const [showShipmentTable, setShowShipmentTable] = useState(false);
  const [showInvoicesTable, setshowInvoicesTable] = useState(false);
  const authToken = localStorage.getItem("token");
  const URL = "https://app.simplymemoirs.com";

  const showUsers = () => {
    setShowTable(true);
    setShowOrdersContent(false);
    setshowInvoicesTable(false);
    setShowShipmentTable(false);
  };
  const showOrders = () => {
    setShowTable(false);
    setShowOrdersContent(true);
    setshowInvoicesTable(false);
    setShowShipmentTable(false);
  };
  const showShipments = () => {
    setShowTable(false);
    setShowOrdersContent(false);
    setshowInvoicesTable(false);
    setShowShipmentTable(true);
  };
  const showInvoices = () => {
    setShowTable(false);
    setShowOrdersContent(false);
    setshowInvoicesTable(true);
    setShowShipmentTable(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${URL}/api/allusers`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchOrders();
    fetchPayments();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${URL}/api/getallorders`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await axios.get(`${URL}/api/getallpayments`);
      setPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await axios.delete(`${URL}/api/userdelete/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user._id !== userId)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderDelete = (id) => {
    axios
      .delete(`${URL}/api/deleteorder/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(() => {
        setOrders(orders.filter((row) => row._id !== id));
      })
      .catch((error) => {
        console.error("Error deleting order:", error);
      });
  };

  return (
    <div className="admin-panel">
      <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-end-lg">
          <div className="container-fluid">
            <button
              className="navbar-toggler ms-n2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="navbar-user d-lg-none">
              <div className="dropdown">
                <a
                  href="#"
                  id="sidebarAvatar"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar-parent-child">
                    <img
                      alt="User"
                      src="https://images.unsplash.com/photo-1548142813-c348350df52b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                      className="avatar avatar-rounded-circle"
                    />
                    <span className="avatar-child avatar-badge bg-success" />
                  </div>
                </a>
              </div>
            </div>

            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showUsers}
                    style={{ color: "#1f1f1f" }} // Primary color
                  >
                    <i
                      className="bi bi-house me-2"
                      style={{ color: "#1f1f1f" }}
                    ></i>
                    <span>Users</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showOrders}
                    style={{ color: "#1f1f1f" }} // Primary color
                  >
                    <i
                      className="bi bi-receipt me-2"
                      style={{ color: "#1f1f1f" }}
                    ></i>
                    <span>Orders</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showShipments}
                    style={{ color: "#1f1f1f" }} // Primary color
                  >
                    <i
                      className="bi bi-box-seam me-2"
                      style={{ color: "#1f1f1f" }}
                    ></i>
                    <span>Shipments</span>
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a
                    className=" d-flex align-items-center nav-link"
                    href="#"
                    onClick={showInvoices}
                    style={{ color: "#1f1f1f" }} // Primary color
                  >
                    <i
                      className="bi bi-file-earmark-text me-2"
                      style={{ color: "#1f1f1f" }}
                    ></i>
                    <span>Invoices</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="h-screen flex-grow-1 overflow-y-lg-auto">
          <header className="bg-surface-primary border-bottom pt-6">
            <div className="container-fluid">
              <div className="mb-npx">
                <div className="row align-items-center">
                  <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                    <h1
                      className="h2 mb-0 ls-tight"
                      style={{ color: "#1f1f1f" }}
                    >
                      Admin Panel
                    </h1>{" "}
                    {/* Purple heading */}
                  </div>
                  <div className="col-sm-6 col-12 text-sm-end">
                    <div className="mx-n1">
                      <a
                        href="#"
                        className="btn d-inline-flex btn-sm btn-neutral border-base mx-1"
                      >
                        <span className="pe-2">
                          <i className="bi bi-pencil" />
                        </span>
                        <span>Edit</span>
                      </a>
                      <a
                        href="#"
                        className="btn d-inline-flex btn-sm btn-primary mx-1"
                        style={{ backgroundColor: "#1f1f1f" }}
                      >
                        <span className="pe-2">
                          <i className="bi bi-plus" />
                        </span>
                        <span>Create</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
              {showTable ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>User Role</StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((row) => (
                        <StyledTableRow key={row._id}>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.email}</StyledTableCell>
                          <StyledTableCell>{row.userRole}</StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              onClick={() => handleDelete(row._id)}
                              color="inherit"
                            >
                              <DeleteOutlineIcon color="error" />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
              {showOrdersContent ? (
                <OrdersTable
                  orders={orders}
                  handleOrderDelete={handleOrderDelete}
                />
              ) : null}
              {showShipmentTable ? <ShipmentTable orders={orders} /> : null}
              {showInvoicesTable ? <InvoicesTable payments={payments} /> : null}
            </div>
          </main>
        </div>
      </div>

      <style jsx>{`
        .nav-link:hover {
          background-color: #1f1f1f !important; /* Light purple background on hover */
          color: white !important; /* White text on hover */
        }
        .nav-link:hover i {
          color: white !important; /* White icon on hover */
        }
      `}</style>
    </div>
  );
}

export default AdminPanel;
