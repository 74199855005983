import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const RequestQuoteForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    coverType: "Soft Cover",
    paperWeight: "80lb White",
    format: "Portrait",
    pageSize: "8.5x11 inches",
    copies: 1,
    totalPages: 0,
    colorPages: 0,
    bookTitle: "",
    additionalInfo: "",
    coverFinish: "Gloss",
    colorChoice: "FULL COLOR",
  });

  const [quote, setQuote] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const pricePerBook = formData.coverType === "Soft Cover" ? 78.38 : 152.13;
    const smallOrderFee = 150;
    const shipping = 85.92;
    const subtotal = formData.copies * pricePerBook;
    const grandTotal = subtotal + smallOrderFee + shipping;

    setQuote({
      pricePerBook,
      smallOrderFee,
      shipping,
      subtotal,
      grandTotal,
    });
  };

  return (
    <div className="container p-4 border rounded">
      <form onSubmit={handleSubmit}>
        <h2 className="mb-4">Request Quote</h2>
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">First Name</label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Address</label>
            <input
              type="text"
              name="address"
              className="form-control"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">City</label>
            <input
              type="text"
              name="city"
              className="form-control"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">State</label>
            <input
              type="text"
              name="state"
              className="form-control"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Zip</label>
            <input
              type="text"
              name="zip"
              className="form-control"
              value={formData.zip}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Phone</label>
            <input
              type="text"
              name="phone"
              className="form-control"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Choose Cover</label>
          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="coverType"
                value="Hard Cover"
                checked={formData.coverType === "Hard Cover"}
                onChange={handleChange}
              />
              <label className="form-check-label">Hard Cover</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="coverType"
                value="Soft Cover"
                checked={formData.coverType === "Soft Cover"}
                onChange={handleChange}
              />
              <label className="form-check-label">Soft Cover</label>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Paper Weight</label>
            <select
              name="paperWeight"
              className="form-select"
              value={formData.paperWeight}
              onChange={handleChange}
            >
              <option value="80lb White">80lb White</option>
              <option value="100lb Premium">100lb Premium</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Cover Finish</label>
            <select
              name="coverFinish"
              className="form-select"
              value={formData.coverFinish}
              onChange={handleChange}
            >
              <option value="Gloss">Gloss</option>
              <option value="Matte">Matte</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">Page Size</label>
            <select
              name="pageSize"
              className="form-select"
              value={formData.pageSize}
              onChange={handleChange}
            >
              <option value="8.5x11 inches">8.5x11 inches</option>
              <option value="8x10 inches">8x10 inches</option>
              <option value="6x9 inches">6x9 inches</option>
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Book Title</label>
          <input
            type="text"
            name="bookTitle"
            className="form-control"
            value={formData.bookTitle}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Additional Information</label>
          <textarea
            name="additionalInfo"
            className="form-control"
            rows="3"
            value={formData.additionalInfo}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label className="form-label">Copies</label>
            <input
              type="number"
              name="copies"
              className="form-control"
              value={formData.copies}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Total Pages</label>
            <input
              type="number"
              name="totalPages"
              className="form-control"
              value={formData.totalPages}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">Color Pages</label>
            <input
              type="number"
              name="colorPages"
              className="form-control"
              value={formData.colorPages}
              onChange={handleChange}
            />
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Submit Request
        </button>
      </form>

      {quote && (
        <div className="mt-4 p-3 border rounded bg-light">
          <h4>Quote Summary</h4>
          <p>
            <strong>Copies:</strong> {formData.copies}
          </p>
          <p>
            <strong>Binding Choice:</strong> {formData.coverType}
          </p>
          <p>
            <strong>Color Choice:</strong> {formData.colorChoice}
          </p>
          <p>
            <strong>Book Format:</strong> {formData.format}
          </p>
          <p>
            <strong>Cover Finish:</strong> {formData.coverFinish}
          </p>
          <p>
            <strong>Book Size:</strong> {formData.pageSize}
          </p>
          <p>
            <strong>Paper Weight:</strong> {formData.paperWeight}
          </p>
          <p>
            <strong>Total Pages:</strong> {formData.totalPages}
          </p>
          <p>
            <strong>Color Pages:</strong> {formData.colorPages}
          </p>
          <p>
            <strong>Price per Book:</strong> ${quote.pricePerBook.toFixed(2)}
          </p>
          <p>
            <strong>Subtotal:</strong> ${quote.subtotal.toFixed(2)}
          </p>
          <p>
            <strong>Small Order Fee:</strong> ${quote.smallOrderFee.toFixed(2)}
          </p>
          <p>
            <strong>Shipping:</strong> ${quote.shipping.toFixed(2)}
          </p>
          <p>
            <strong>Grand Total:</strong> ${quote.grandTotal.toFixed(2)}
          </p>
        </div>
      )}
    </div>
  );
};

export default RequestQuoteForm;
