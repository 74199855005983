// reducers.js
import {
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  TOGGLE_EDITOR_READONLY,
} from "../actiontype/actiontype";

const initialState = {
  orderData: null,
  error: null,
  readOnly: true,
};

const editororderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        orderData: action.payload,
        error: null,
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        orderData: null,
        error: action.payload,
      };
    case TOGGLE_EDITOR_READONLY:
      return {
        ...state,
        readOnly: action.payload,
      };
    default:
      return state;
  }
};

export default editororderReducer;
