
// import Quill from "quill";
// const Module = Quill.import("core/module");

// class CustomImageToolbar extends Module {
//     constructor(quill, options) {
//         super(quill, options);
//         this.filters = options.filters || [];
//         this.initToolbar();
//         this.initImageClickHandler();
//         this.visibleMenu = null; // Track currently visible menu
//     }

//     initToolbar() {
//         this.toolbar = document.createElement("div");
//         this.toolbar.className = "quill-image-toolbar";
//         this.toolbar.style.display = "none";
//         this.toolbar.style.position = "absolute";

//         const buttonNames = ["Filter", "Borders", "Border Sizes", "Shadows"];
//         buttonNames.forEach((name) => {
//             const button = document.createElement("button");
//             button.innerText = name;
//             button.className = `toolbar-button toolbar-${name.toLowerCase().replace(" ", "-")}`;
//             button.addEventListener("click", () => this.handleButtonClick(name));
//             this.toolbar.appendChild(button);
//         });

//         document.body.appendChild(this.toolbar);
//     }

//     initImageClickHandler() {
//         this.quill.root.addEventListener("click", (event) => {
//             if (event.target && event.target.tagName === "IMG") {
//                 this.showToolbar(event.target);
//             } else {
//                 this.hideToolbar();
//             }
//         });
//     }

//     showToolbar(image) {
//         const rect = image.getBoundingClientRect();
//         this.toolbar.style.display = "flex";
//         this.toolbar.style.top = `${rect.bottom + window.scrollY}px`;
//         this.toolbar.style.left = `${rect.left + window.scrollX}px`;
//         this.currentImage = image;
//     }

//     hideToolbar() {
//         this.toolbar.style.display = "none";
//         this.hideMenus();
//         this.currentImage = null;
//     }

//     hideMenus() {
//         if (this.visibleMenu) {
//             this.visibleMenu.remove();
//             this.visibleMenu = null;
//         }
//     }



//     handleButtonClick(action) {
//         if (!this.currentImage) return;

//         this.hideMenus();

//         const button = this.toolbar.querySelector(`.toolbar-${action.toLowerCase().replace(" ", "-")}`);
//         const rect = button.getBoundingClientRect();

//         switch (action) {
//             case "Filter":
//                 this.showFilterList(rect);
//                 break;
//             case "Borders":
//                 this.showBorderList(rect);
//                 break;
//             case "Border Sizes":
//                 this.showBorderSizeList(rect);
//                 break;
//             case "Shadows":
//                 this.showShadowList(rect);
//                 break;
//             default:
//                 console.log(`${action} clicked for the image`);
//                 break;
//         }
//     }

//     createMenu(content, buttonRect) {
//         const menuContainer = document.createElement("div");
//         menuContainer.className = "menu-container";
//         menuContainer.style.position = "absolute";

//         // Position the menu below the clicked button
//         menuContainer.style.top = `${buttonRect.bottom + window.scrollY}px`;
//         menuContainer.style.left = `${buttonRect.left + window.scrollX}px`;

//         menuContainer.appendChild(content);
//         document.body.appendChild(menuContainer);
//         this.visibleMenu = menuContainer; // Track currently visible menu
//     }

//     showFilterList(buttonRect) {
//         const list = document.createElement("ul");
//         list.className = "filter-list";

//         this.filters.forEach((filterObj, index) => {
//             const listItem = document.createElement("li");
//             const img = document.createElement("img");
//             img.src = filterObj.preview;
//             img.alt = `Filter ${index}`;
//             img.style.filter = filterObj.filter;
//             img.style.margin = "10px";
//             img.style.width = "100px";

//             img.addEventListener("click", () => {
//                 this.applyStyleToImage("filter", filterObj.filter);
//                 this.hideMenus(); // Close menu after applying filter
//             });

//             listItem.appendChild(img);
//             list.appendChild(listItem);
//         });

//         this.createMenu(list, buttonRect);
//     }

//     showBorderList(buttonRect) {
//         const borderStyles = ["20%", "30%", "40%", "50%"];
//         const list = document.createElement("ul");
//         list.className = "border-list";

//         borderStyles.forEach((size) => {
//             const listItem = document.createElement("li");
//             const div = document.createElement("div");
//             div.style.width = "30px";
//             div.style.height = "30px";
//             div.style.margin = "10px";
//             div.style.border = "4px double black";
//             div.style.borderImage = `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`;

//             div.addEventListener("click", () => {
//                 this.applyStyleToImage("borderImage", `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`);
//                 this.hideMenus(); // Close menu after applying border
//             });

//             listItem.appendChild(div);
//             list.appendChild(listItem);
//         });

//         this.createMenu(list, buttonRect);
//     }

//     showBorderSizeList(buttonRect) {
//         const borderSizes = ["2px", "4px", "6px", "8px", "10px"];
//         const list = document.createElement("ul");
//         list.className = "border-size-list";

//         borderSizes.forEach((size) => {
//             const listItem = document.createElement("li");
//             const div = document.createElement("div");
//             div.style.display = "flex";
//             div.style.justifyContent = "center";
//             div.style.alignItems = "center";
//             div.style.borderRadius = "20px";
//             div.style.width = "30px";
//             div.style.height = "30px";
//             div.style.margin = "10px";
//             div.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.1)";
//             div.innerText = size.replace("px", "x");

//             div.addEventListener("click", () => {
//                 this.applyStyleToImage("borderWidth", size);
//                 this.hideMenus(); // Close menu after applying border size
//             });

//             listItem.appendChild(div);
//             list.appendChild(listItem);
//         });

//         this.createMenu(list, buttonRect);
//     }

//     showShadowList(buttonRect) {
//         const list = document.createElement("ul");
//         list.className = "shadow-list";

//         const listItem = document.createElement("li");
//         const div = document.createElement("div");
//         div.style.display = "flex";
//         div.style.justifyContent = "center";
//         div.style.alignItems = "center";
//         div.style.borderRadius = "20px";
//         div.style.width = "30px";
//         div.style.height = "30px";
//         div.style.margin = "10px";
//         div.style.boxShadow = "8px 8px 10px rgba(0, 0, 0, 0.4)";
//         div.innerText = "8x";

//         div.addEventListener("click", () => {
//             this.applyStyleToImage("boxShadow", "8px 8px 10px rgba(0, 0, 0, 0.4)");
//             this.hideMenus(); // Close menu after applying shadow
//         });

//         listItem.appendChild(div);
//         list.appendChild(listItem);

//         this.createMenu(list, buttonRect);
//     }

//     applyStyleToImage(style, value) {
//         if (this.currentImage) {
//             this.currentImage.style[style] = value;
//         }
//     }
// }

// Quill.register("modules/customImageToolbar", CustomImageToolbar);

// export default CustomImageToolbar;


import Quill from "quill";
const Module = Quill.import("core/module");

class CustomImageToolbar extends Module {
    constructor(quill, options) {
        super(quill, options);
        this.filters = options.filters || [];
        this.initToolbar();
        this.initImageClickHandler();
        this.visibleMenu = null; // Track currently visible menu
    }

    initToolbar() {
        this.toolbar = document.createElement("div");
        this.toolbar.className = "quill-image-toolbar";
        this.toolbar.style.display = "none";
        this.toolbar.style.position = "absolute";

        const buttonNames = ["Filter", "Borders", "Border Sizes", "Shadows"];
        buttonNames.forEach((name) => {
            const button = document.createElement("button");
            button.innerText = name;
            button.className = `toolbar-button toolbar-${name.toLowerCase().replace(" ", "-")}`;
            button.addEventListener("click", () => this.handleButtonClick(name));
            this.toolbar.appendChild(button);
        });

        document.body.appendChild(this.toolbar);
    }

    initImageClickHandler() {
        this.quill.root.addEventListener("click", async (event) => {
            if (event.target && event.target.tagName === "IMG") {
                this.currentImage = event.target;
                await this.loadStyles(this.currentImage); // Load styles from DB
                this.showToolbar(event.target);
            } else {
                this.hideToolbar();
            }
        });
    }

    showToolbar(image) {
        const rect = image.getBoundingClientRect();
        this.toolbar.style.display = "flex";
        this.toolbar.style.top = `${rect.bottom + window.scrollY}px`;
        this.toolbar.style.left = `${rect.left + window.scrollX}px`;
    }

    hideToolbar() {
        this.toolbar.style.display = "none";
        this.hideMenus();
        this.currentImage = null;
    }

    hideMenus() {
        if (this.visibleMenu) {
            this.visibleMenu.remove();
            this.visibleMenu = null;
        }
    }

    handleButtonClick(action) {
        if (!this.currentImage) return;

        this.hideMenus();

        const button = this.toolbar.querySelector(`.toolbar-${action.toLowerCase().replace(" ", "-")}`);
        const rect = button.getBoundingClientRect();

        switch (action) {
            case "Filter":
                this.showFilterList(rect);
                break;
            case "Borders":
                this.showBorderList(rect);
                break;
            case "Border Sizes":
                this.showBorderSizeList(rect);
                break;
            case "Shadows":
                this.showShadowList(rect);
                break;
            default:
                console.log(`${action} clicked for the image`);
                break;
        }
    }

    createMenu(content, buttonRect) {
        const menuContainer = document.createElement("div");
        menuContainer.className = "menu-container";
        menuContainer.style.position = "absolute";

        // Position the menu below the clicked button
        menuContainer.style.top = `${buttonRect.bottom + window.scrollY}px`;
        menuContainer.style.left = `${buttonRect.left + window.scrollX}px`;

        menuContainer.appendChild(content);
        document.body.appendChild(menuContainer);
        this.visibleMenu = menuContainer; // Track currently visible menu
    }

    applyStyleToImage(style, value) {
        if (this.currentImage) {
            this.currentImage.style[style] = value;
            this.saveStyles(this.currentImage); // Save styles to DB
        }
    }

    async saveStyles(image) {
        const id = image.getAttribute("data-id");
        const styles = image.style.cssText; // Get current styles
        await this.saveStylesToDatabase(id, styles); // Save to backend
    }

    async loadStyles(image) {
        const id = image.getAttribute("data-id");
        const styles = await this.loadStylesFromDatabase(id); // Fetch from backend

        if (styles) {
            image.style.cssText = styles; // Apply styles to the image
        }
    }

    async saveStylesToDatabase(id, styles) {
        // Replace this with your actual API logic to save styles
        console.log(`Saving styles for image ID ${id}: ${styles}`);
        // Example: await fetch(`/api/save-styles`, { method: "POST", body: JSON.stringify({ id, styles }) });
    }

    async loadStylesFromDatabase(id) {
        // Replace this with your actual API logic to load styles
        console.log(`Loading styles for image ID ${id}`);
        // Example: return await fetch(`/api/get-styles?id=${id}`).then((res) => res.text());
        return ""; // Return the styles as a string
    }

    showFilterList(buttonRect) {
        const list = document.createElement("ul");
        list.className = "filter-list";

        this.filters.forEach((filterObj, index) => {
            const listItem = document.createElement("li");
            const img = document.createElement("img");
            img.src = filterObj.preview;
            img.alt = `Filter ${index}`;
            img.style.filter = filterObj.filter;
            img.style.margin = "10px";
            img.style.width = "100px";

            img.addEventListener("click", () => {
                this.applyStyleToImage("filter", filterObj.filter);
            });

            listItem.appendChild(img);
            list.appendChild(listItem);
        });

        this.createMenu(list, buttonRect);
    }

    showBorderList(buttonRect) {
        const borderStyles = ["20%", "30%", "40%", "50%"];
        const list = document.createElement("ul");
        list.className = "border-list";

        borderStyles.forEach((size) => {
            const listItem = document.createElement("li");
            const div = document.createElement("div");
            div.style.width = "30px";
            div.style.height = "30px";
            div.style.margin = "10px";
            div.style.border = "4px double black";
            div.style.borderImage = `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`;

            div.addEventListener("click", () => {
                this.applyStyleToImage("borderImage", `url('https://simplymemoirs.s3.us-west-1.amazonaws.com/images/border.png') ${size} round`);
            });

            listItem.appendChild(div);
            list.appendChild(listItem);
        });

        this.createMenu(list, buttonRect);
    }

    showBorderSizeList(buttonRect) {
        const borderSizes = ["2px", "4px", "6px", "8px", "10px"];
        const list = document.createElement("ul");
        list.className = "border-size-list";

        borderSizes.forEach((size) => {
            const listItem = document.createElement("li");
            const div = document.createElement("div");
            div.style.display = "flex";
            div.style.justifyContent = "center";
            div.style.alignItems = "center";
            div.style.borderRadius = "20px";
            div.style.width = "30px";
            div.style.height = "30px";
            div.style.margin = "10px";
            div.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.1)";
            div.innerText = size.replace("px", "x");

            div.addEventListener("click", () => {
                this.applyStyleToImage("borderWidth", size);
            });

            listItem.appendChild(div);
            list.appendChild(listItem);
        });

        this.createMenu(list, buttonRect);
    }

    showShadowList(buttonRect) {
        const list = document.createElement("ul");
        list.className = "shadow-list";

        const listItem = document.createElement("li");
        const div = document.createElement("div");
        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.style.alignItems = "center";
        div.style.borderRadius = "20px";
        div.style.width = "30px";
        div.style.height = "30px";
        div.style.margin = "10px";
        div.style.boxShadow = "8px 8px 10px rgba(0, 0, 0, 0.4)";
        div.innerText = "8x";

        div.addEventListener("click", () => {
            this.applyStyleToImage("boxShadow", "8px 8px 10px rgba(0, 0, 0, 0.4)");
        });

        listItem.appendChild(div);
        list.appendChild(listItem);

        this.createMenu(list, buttonRect);
    }
}

Quill.register("modules/customImageToolbar", CustomImageToolbar);

export default CustomImageToolbar;
