import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function ShipmentTable({ orders }) {
  const [selectedStatus, setSelectedStatus] = useState({});

  const handleChange = (event, orderId) => {
    setSelectedStatus({ ...selectedStatus, [orderId]: event.target.value });
  };

  const filteredOrders = orders.filter((order) => order.shipmentData);
  const sortedOrders = filteredOrders.slice().sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              #
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Order
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Name
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Phone No
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Tracking Number
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Postal Code
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Address
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                backgroundColor: "#6a1ac3", // Primary background color
                color: "white", // White text color
              }}
            >
              Shipment Charges
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedOrders.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "40px",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ padding: 0 }}
                align="center"
              >
                {index + 1}
              </TableCell>
              <TableCell align="center">{row.orderName}</TableCell>
              <TableCell align="center">
                {row.shipmentData.recipientName}
              </TableCell>
              <TableCell align="center">
                {row.shipmentData.recipientNumber}
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    borderRadius: "25px",
                    margin: "0 auto",
                    padding: "5px",
                    width: "60%",
                  }}
                >
                  {
                    row.shipmentResponse?.ShipmentResults
                      ?.ShipmentIdentificationNumber
                  }
                </div>
              </TableCell>
              <TableCell align="center">
                {row.shipmentData.recipientZipCode}
              </TableCell>

              <TableCell align="center">
                {row.shipmentData.recipientAddress},
                {row.shipmentData.recipientCity},
                {row.shipmentData.recipientState},
                {row.shipmentData.recipientCountry}
              </TableCell>
              <TableCell align="center">
                $
                {
                  row.shipmentResponse?.ShipmentResults?.ShipmentCharges
                    ?.TotalCharges?.MonetaryValue
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ShipmentTable;
